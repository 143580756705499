import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';
import {
  updateUser as updateUserProps,
  detailUser as detailUserProps,
  deleteUserPermission as deleteUserPermissionProps,
  getUserPermissionList as getUserPermissionListProps,
  createUser as createUserProps,
  getUserRoles as getUserRolesProps,
  uploadImage as uploadImageProps
} from '@myrepublic-users/usersAction';
import { Delete } from '@material-ui/icons';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DropzoneArea } from 'material-ui-dropzone';
import {
  Snackbar,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { values } from 'lodash';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

function CreateCustomerJourney(props) {
  const {
    updateUser,
    updateUserResponse,
    detailUser,
    getUserRoles, 
    getUserRolesResponse,
    createUser, 
    createUserResponse, 
    detailUserResponse,
    deleteUserPermissionResponse,
    deleteUserPermission,
    getUserPermissionList,
    getUserPermissionListResponse,
    uploadImage,
    uploadImageResponse,
    uploadImageFetch,
    uploadImageError,
    icon,
    // match: { params },
  } = props;
  const [data, setData] = useState({profileImageUrl: null, devicePackageName: "WEB",});
  const [description, setDescription] = useState({ value: '' });
  const [openErrorInfo, setOpenErrorInfo] = useState(false);
  const [errorInfo, setErrorInfo] = useState('');
  const [userPermissions, setUserPermissions] = useState({ data: [] });
  const [permissionOptions, setPermissionOptions] = useState([]);
  const history = useHistory();
  const [key, setKey] = useState(0);

  let closeToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenErrorInfo(false);
  };

  const dataVal = () => {
    const value = userPermissions.data;
    let data = [];
    if (typeof value === 'object') {
      data = value.map((userPermission, index) => {
        console.log('userPermission <<<< ', userPermission);
        if (userPermission.id === undefined) {
          return {
            permissionId: userPermission.permissionId,
          };
        }
        return {
          id: userPermission.id,
          permissionId: userPermission.permissionId,
        };
      });
      return values(data);
    }
  };

  const handleOpenErrorInfo = () => {
    setOpenErrorInfo(true);
  };

  const validateCreateForm = () => {
    let userPermissionList = dataVal();
    if (data?.permissionId === '') {
      setErrorInfo('Nama Permission harus diisi');
      handleOpenErrorInfo();
      return false;
    } else {
      userPermissionList.map((items) => {
        if (
          items.permissionId === undefined ||
          items.permissionId === 'undefined' ||
          items.permissionId === ''
        ) {
          setErrorInfo('Lengkapi semua field data pada permission list');
          handleOpenErrorInfo();
          return false;
        }
      });
    }
    return true;
  };
  const handleOnSave = () => {
    // if (validateCreateForm() === true) {
      const sendData = {
        ...data,
        userPermissionList: userPermissions.data,
      };
      console.log('SEND DATA', sendData);

      createUser({
        ...sendData
      })
  };

  const onUploadImage = (image) => {
    if (image?.length > 0) {
      uploadImage({ data: { image: image[0], type: 'PROFILE' } });
    }
  };

  useEffect(() => {
    if (createUserResponse?.code === 200) {
      toast.success(createUserResponse?.message);
      setTimeout(() => {
        history.replace('/customer-journey');
      }, 3000);
    }
  }, [createUserResponse]);

//   useEffect(() => {
//     if (detailUserResponse?.code === 200) {
//       setData(detailUserResponse?.data);
//       detailUserResponse?.data?.userPermissions?.map((i, index) => {
//         userPermissions?.data.push({ id: i.id, permissionId: i.permission.id, permissionName: i.permission.name + " > " + i.permission.access});
//       });
//       console.log(detailUserResponse?.data, 'log>>');
//       // setItem({ data: detailUserResponse?.addOnItems });
//     }
//   }, [detailUserResponse]);

  useEffect(() => {
    setUserPermissions({ data: [] });
    getUserRoles();
    getUserPermissionList();
  }, []);

  useEffect(() => {
    const index = getUserRolesResponse?.data?.content?.findIndex((i) => i.name === "Customer Journey");
    setData({...data, roleId: getUserRolesResponse?.data?.content[index]?.id});
  }, [getUserRolesResponse]);

  useEffect(() => {
    setUserPermissions({ data: [] });
    // detailUser({ id: params?.id });
  }, [deleteUserPermissionResponse]);

  console.log('detailUserResponse >>> ', detailUserResponse);
  useEffect(() => {
    if (uploadImageResponse?.url !== undefined) {
      setData({ ...data, profileImageUrl: uploadImageResponse?.url });
    }
  }, [uploadImageResponse]);

  useEffect(() => {
    if (uploadImageError?.error !== undefined && uploadImageError?.message) {
      setErrorInfo(uploadImageError?.message);
      handleOpenErrorInfo();
      setKey();
    }
  }, [uploadImageError]);

  useEffect(() => {
    if (getUserPermissionListResponse?.code === 200) {
      const _permissionOptions = [];
      getUserPermissionListResponse?.data?.map((value, index) => {
        _permissionOptions.push(value);
      });
      setPermissionOptions(_permissionOptions);
      console.log('_permissionOptions >> ', _permissionOptions);
    }
  }, [getUserPermissionListResponse]);

  const ItemListComponent = () => {

    const onChange = (e, index) => {
      const { name, value } = e.target;
      const newArray = [...userPermissions.data];
      newArray[index][name] = value;
      setUserPermissions({ data: newArray });
    };

    const onChangeUserPermission = (e, index) => {
        const { value } = e.target;
        console.log("checked", value);
        console.log("user permission", userPermissions);
        if (value !== 'initial') {
            const newArray = [...userPermissions.data];
            newArray.push({permissionId: Number(value)});
            setUserPermissions({ data: newArray });
        }
    
    };

    const deleteHandler = async (id, index) => {
      if (id !== undefined) {
        await deleteUserPermission({ id: id });
      }
      const tempItem = userPermissions.data.splice(index, 1);
      setUserPermissions({ data: userPermissions.data });
    };

    const checkedValue = (value) => {
        const index = userPermissions.data.findIndex((i) => i.permissionId == value)
        if(index === -1) return false 
        else return true
    }

    return (
      <Form.Group className="mb-3" controlId="formBasicEmail">
        {/* <Form.Label>Hak Akses</Form.Label> */}
        <h6>Hak Akses</h6>
        {permissionOptions?.map((value, index) => {
            // console.log("value checkbox >>> ", value)
            return (<Form.Check 
              type={'checkbox'}
              id={`permission-${index}`}
              value={value.value}
              label={value.label}
              checked={checkedValue(value.value)}
              onChange={(e) => onChangeUserPermission(e, index)}
            />)
          })}
      </Form.Group>
    );
  };
  return (
    <Card className="mx-4 card-box">
      <CardHeader>
        <CardTitle>
            <h5><b>Create Customer Journey Form</b></h5>
        </CardTitle>
      </CardHeader>
      <CardBody className="p-3">
        <Form>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                    <h6>Username</h6>
                <Form.Control
                  value={data?.username}
                  type="text"
                  placeholder="Username"
                  onChange={(e) => setData({ ...data, username: e.target.value })}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                {/* <Form.Label> */}
                    <h6>Password</h6>
                {/* </Form.Label> */}
                <Form.Control
                  value={data?.password}
                  type="password"
                  placeholder="Password"
                  onChange={(e) => setData({ ...data, password: e.target.value })}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                {/* <Form.Label> */}
                    <h6>Name</h6>
                {/* </Form.Label> */}
                <Form.Control
                  value={data?.name}
                  type="text"
                  placeholder="Nama"
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <h6>Handphone</h6>
                <Form.Control
                  value={data?.handphone}
                  type="text"
                  placeholder="Handphone"
                  onChange={(e) => setData({ ...data, handphone: e.target.value })}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h6>Email</h6>
                <Form.Control
                  value={data?.email}
                  type="email"
                  placeholder="Email"
                  onChange={(e) => setData({ ...data, email: e.target.value })}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <h6>Role</h6>
                <Form.Control
                  value={'Customer Journey'}
                  type="text"
                  disabled
                  placeholder="Role"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h6>Max Queue Chat</h6>
                <Form.Control
                  value={data?.maxQueueChat}
                  type="number"
                  placeholder="Max Queue Chat"
                  onChange={(e) => {
                    let value= Number(e.target.value)
                    setData({ ...data, maxQueueChat: value })
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <DropzoneArea
                filesLimit={1}
                maxFileSize={3000000}
                onChange={onUploadImage}
                acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                dropzoneText={
                  'Drag and drop gambar atau klik disini untuk mengupload'
                }
                showAlerts={false}
                showFileNames={true}
                key={key}
              />
            </Col>
          </Row>
          {ItemListComponent()}
          
        </Form>
      </CardBody>
      <CardFooter>
        <Button
          className="bg-primary"
          onClick={handleOnSave}
          disabled={updateUserResponse.code === 200 ? true : false}>
          Simpan
        </Button>
      </CardFooter>
    </Card>
  );
}
const mapStateToProps = (state) => ({
  updateUserResponse: state.user.updateUserResponse,
  createUserResponse: state.user.createUserResponse,
  detailUserResponse: state.user.detailUserResponse,
  deleteUserPermissionResponse: state.user.deleteUserPermissionResponse,
  getUserPermissionListResponse: state.user.getUserPermissionListResponse,
  getUserRolesResponse: state.user.getUserRolesResponse,
  uploadImageResponse: state.user.uploadImageResponse,
  uploadImageError: state.user.uploadImageError,
  icon: state.product.icon,
});
const mapDispatchToProps = {
  updateUser: (payload) => updateUserProps(payload),
  createUser: (payload) => createUserProps(payload),
  detailUser: (payload) => detailUserProps(payload),
  uploadImage: (payload) => uploadImageProps(payload),
  deleteUserPermission: (payload) => deleteUserPermissionProps(payload),
  getUserPermissionList: (payload) => getUserPermissionListProps(payload),
  getUserRoles: (payload) => getUserRolesProps(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCustomerJourney);
