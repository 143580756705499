import * as CONST from '@myrepublic-forgotPassword/forgotPasswordConstant';
import * as STATE from '@myrepublic-forgotPassword/forgotPasswordInitialState';

const initialState = {
  ...STATE.forgotPasswordInitialState,
  action: '',
};

export const forgotPasswordReducer = (state = initialState, action) => {
  const { payload, type } = action;

  const actions = {
    [CONST.FORGOT_PASSWORD]: () => ({
      ...state,
      forgotPasswordFetch: true,
      forgotPasswordParams: payload,
      action: type,
    }),
    [CONST.FORGOT_PASSWORD_SUCCESS]: () => ({
      ...state,
      forgotPasswordFetch: false,
      forgotPasswordResponse: payload,
      action: type,
    }),
    [CONST.FORGOT_PASSWORD_FAILED]: () => ({
      ...state,
      forgotPasswordFetch: false,
      forgotPasswordError: payload,
      action: type,
    }),

    DEFAULT: () => state,
  };
  return (actions[type] || actions.DEFAULT)();
};

export default forgotPasswordReducer;
