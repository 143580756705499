import React, { createRef } from 'react';
import {
  CssBaseline,
  Link,
  Box,
  Typography,
  Container,
  Grid,
} from '@material-ui/core';
import Styles from '../styles';

const Unauthorized = () => {
  const ref = createRef();
  const styles = Styles();

  return (
    <Container
      component="main"
      maxWidth="xs"
      className={styles.container}
      ref={ref}>
      <CssBaseline />
      <div className={styles.paper}>
        <img src="logo.png" className={styles.logo} />
        <form className={styles.form} style={{textAlign: 'center'}}>
          <p style={{fontSize: 14, fontWeight: 'bold'}}>
            Akses halaman tidak diijinkan. <br />Silahkan hubungi Administrator.
          </p>
          <Grid container>
            <Grid item xs>
              <Link href="/" variant="body2">
                Kembali ke dashboard?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="#">
            My Republic
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Box>
    </Container>
  );
};

export default Unauthorized;
