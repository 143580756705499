import React, { useEffect, useState, createRef } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Styles from './styles';
import { finishPassword } from '../finishPasswordAction';

const FinishPassword = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const ref = createRef();
  const styles = Styles();
  const [inputs, setInputs] = useState({
    newPassword: '',
    newPasswordConfirm: '',
  });
  const [submitted, setSubmitted] = useState(false);
  const [open, setOpen] = useState(false);

  const dataRed = useSelector((state) => state.dataStore);

  let closeToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  let handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  let handleSubmit = (e) => {
    e.preventDefault();

    setSubmitted(true);
    if (newPassword && newPasswordConfirm) {
      const req = {
        newPassword,
        newPasswordConfirm,
      };
      dispatch(finishPassword(req));
    }
  };

  const { newPassword, newPasswordConfirm } = inputs;

  useEffect(() => {
    if (dataRed.token && dataRed.user) {
      history.replace(location.state ? location.state.from.pathname : '/');
    }
  });

  useEffect(() => {
    if (dataRed.message !== '') {
      setOpen(true);
    }
  }, [dataRed.error]);

  return (
    <Container component="main" maxWidth="xs" className="container" ref={ref}>
      <Snackbar open={open} autoHideDuration={4000} onClose={closeToast}>
        <Alert
          onClose={closeToast}
          severity={dataRed.error ? 'error' : 'success'}>
          {dataRed.message}
        </Alert>
      </Snackbar>
      <CssBaseline />
      <div className="paper">
        <img src="logo.png" className="logo" />
        <form className="form" onSubmit={handleSubmit}>
          <h2>Anda telah berhasil ganti password.</h2>
          <Link href="/login" variant="body2">
            Kembali
          </Link>

          <Grid container>
            <Grid item xs></Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="#">
            My Republic
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Box>
    </Container>
  );
};

export default FinishPassword;
