import { call, put, takeLatest, select } from 'redux-saga/effects';
import { RESPONSE_STATUS } from '@myrepublic-config/env';
import {
  resetPasswordApi,
  formResetPasswordApi,
} from '@myrepublic-resetPassword/resetPasswordApi';
import {
  resetPasswordSuccess,
  resetPasswordFailed,
  formResetPasswordSuccess,
  formResetPasswordFailed,
} from '@myrepublic-resetPassword/resetPasswordAction';
import * as CONST from '@myrepublic-resetPassword/resetPasswordConstant';

function* resetPasswordSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(resetPasswordApi, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(resetPasswordSuccess(response.data));
        break;
      default:
        yield put(resetPasswordFailed(response.data));
        break;
    }
  } catch (error) {
    if (error.response) {
      switch (error.response.status) {
        case RESPONSE_STATUS.UNAUTHORIZED:
          break;
        default:
          yield put(resetPasswordFailed(error.response));
          break;
      }
    } else {
      yield put(resetPasswordFailed(error.response));
    }
  }
}

function* formResetPasswordSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(formResetPasswordApi, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(formResetPasswordSuccess(response.data));
        break;
      default:
        window.location.href = '/login';
        yield put(formResetPasswordFailed(response.data));
        break;
    }
  } catch (error) {
    if (error.response) {
      switch (error.response.status) {
        case RESPONSE_STATUS.UNAUTHORIZED:
          break;
        default:
          window.location.href = '/login';
          yield put(formResetPasswordFailed(error.response));
          break;
      }
    } else {
      window.location.href = '/login';
      yield put(formResetPasswordFailed(error.response));
    }
  }
}

export default [
  takeLatest(CONST.RESET_PASSWORD, resetPasswordSaga),
  takeLatest(CONST.FORM_RESET_PASSWORD, formResetPasswordSaga),
];
