export const productInitialState = {
  getProductFetch: false,
  getProductsParams: {},
  getProductsResponse: [],
  getProductError: {
    message: '',
  },
  getProductTvFetch: false,
  getProductsTvParams: {},
  getProductsTvResponse: [],
  getProductTvError: {
    message: '',
  },

  postProductFetch: false,
  postProductParams: {},
  postProductResponse: [],
  postProductError: {
    message: '',
  },

  putProductFetch: false,
  putProductParams: {},
  putProductResponse: [],
  putProductError: {
    message: '',
  },

  deleteProductFetch: false,
  deleteProductParams: {},
  deleteProductResponse: [],
  deleteProductError: {
    message: '',
  },

  deleteProductAddOnFetch: false,
  deleteProductAddOnParams: {},
  deleteProductAddOnResponse: [],
  deleteProductAddOnError: {
    message: '',
  },

  detailProductFetch: false,
  detailProductParams: {},
  detailProductResponse: [],
  detailProductError: {
    message: '',
  },

  uploadImageFetch: false,
  uploadImageParams: {},
  uploadImageResponse: [],
  uploadImageError: {
    message: '',
  },
};
