import { call, put, takeLatest, select } from 'redux-saga/effects';
import { RESPONSE_STATUS } from '@myrepublic-config/env';
import {
  getDataMessages,
  getDataSelectedMessage,
  postDataMessage,
  putReadMessage,
  takeByCsagent,
  closeChatByCsagent,
  uploadDataImage,
  postLiveChatSettings,
  getDetailLiveChatSetting,
  putDetailLiveChatSetting,
  postCSAgentChatStatistics,
  getCSAgentChatStatisticsSummary,
  postCSAgentChatMonitoring,
  postCSAgentForceLogout,
  postCSAgentHistory,
  postCSAgentChatStatisticsById 
} from '@myrepublic-livechat/liveChatApi';
import {
  getMessagesFailed,
  getMessagesSuccess,
  getSelectedMessageFailed,
  getSelectedMessageSuccess,
  postMessageFailed,
  postMessageSuccess,
  putReadMessageFailed,
  putReadMessageSuccess,
  takeByCsagentFailed,
  takeByCsagentSuccess,
  closeChatByCsagentFailed,
  closeChatByCsagentSuccess,
  uploadImageFailed,
  uploadImageSuccess,
  setImageUrl, 
  postLiveChatSettingsFailed,
  postLiveChatSettingsSuccess,
  getDetailLiveChatSettingFailed, 
  getDetailLiveChatSettingSuccess,
  putDetailLiveChatSettingFailed,
  putDetailLiveChatSettingSuccess,
  postCSAgentChatStatisticsFailed,
  postCSAgentChatStatisticsSuccess,
  postCSAgentChatSummaryFailed,
  postCSAgentChatSummarySuccess,
  postCSAgentChatMonitoringFailed,
  postCSAgentChatMonitoringSuccess,
  postCSAgentForceLogoutSuccess,
  postCSAgentForceLogoutFailed,
  postCSAgentHistoryFailed, 
  postCSAgentHistorySuccess,
  postCSAgentChatStatisticsByIdFailed,
  postCSAgentChatStatisticsByIdSuccess
} from '@myrepublic-livechat/liveChatAction';
import * as CONST from '@myrepublic-livechat/liveChatConstant';

function* getMessagesSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(getDataMessages, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(getMessagesSuccess(response.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(getMessagesFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(getMessagesFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(getMessagesFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(getMessagesFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(getMessagesFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(getMessagesFailed(error.response));
        break;
      default:
    }
  }
}

function* getSelectedMessageSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(getDataSelectedMessage, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(getSelectedMessageSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(getSelectedMessageFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(getSelectedMessageFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(getSelectedMessageFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(getSelectedMessageFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(getSelectedMessageFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(getSelectedMessageFailed(error.response));
        break;
      default:
    }
  }
}

function* postMessageSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(postDataMessage, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(postMessageSuccess(response.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(postMessageFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(postMessageFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(postMessageFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(postMessageFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(postMessageFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(postMessageFailed(error.response));
        break;
      default:
    }
  }
}

function* putReadMessageSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(putReadMessage, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(putReadMessageSuccess(response.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(putReadMessageFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(putReadMessageFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(putReadMessageFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(putReadMessageFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(putReadMessageFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(putReadMessageFailed(error.response));
        break;
      default:
    }
  }
}

function* takeByCsagentSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(takeByCsagent, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(takeByCsagentSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(takeByCsagentFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(takeByCsagentFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(takeByCsagentFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(takeByCsagentFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(takeByCsagentFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(takeByCsagentFailed(error.response));
        break;
      default:
    }
  }
}

function* closeChatByCsagentSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(closeChatByCsagent, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(closeChatByCsagentSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(closeChatByCsagentFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(closeChatByCsagentFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(closeChatByCsagentFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(closeChatByCsagentFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(closeChatByCsagentFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(closeChatByCsagentFailed(error.response));
        break;
      default:
    }
  }
}

function* uploadImageSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(uploadDataImage, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(uploadImageSuccess(response.data.data));
        break;
      default:
        yield put(uploadImageFailed(response.data));
        break;
    }
  } catch (error) {
    if (error.response) {
      switch (error.response.status) {
        case RESPONSE_STATUS.UNAUTHORIZED:
          break;
        default:
          yield put(uploadImageFailed(error.response.data));
          break;
      }
    } else {
      yield put(uploadImageFailed(error.response.data));
    }
  }
}

function* postLiveChatSettingsSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    console.log("it on reducer")
    const response = yield call(postLiveChatSettings, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(postLiveChatSettingsSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(postLiveChatSettingsFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(postLiveChatSettingsFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(postLiveChatSettingsFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(postLiveChatSettingsFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(postLiveChatSettingsFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(postLiveChatSettingsFailed(error.response));
        break;
      default:
    }
  }
}

function* getDetailLiveChatSettingSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(getDetailLiveChatSetting, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(getDetailLiveChatSettingSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(getDetailLiveChatSettingFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(getDetailLiveChatSettingFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(getDetailLiveChatSettingFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(getDetailLiveChatSettingFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(getDetailLiveChatSettingFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(getDetailLiveChatSettingFailed(error.response));
        break;
      default:
    }
  }
}

function* putDetailLiveChatSettingSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(putDetailLiveChatSetting, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(putDetailLiveChatSettingSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(putDetailLiveChatSettingFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(putDetailLiveChatSettingFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(putDetailLiveChatSettingFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(putDetailLiveChatSettingFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(putDetailLiveChatSettingFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(putDetailLiveChatSettingFailed(error.response));
        break;
      default:
    }
  }
}

function* postCSAgentChatStatisticsSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(postCSAgentChatStatistics, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(postCSAgentChatStatisticsSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(postCSAgentChatStatisticsFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(postCSAgentChatStatisticsFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(postCSAgentChatStatisticsFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(postCSAgentChatStatisticsFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(postCSAgentChatStatisticsFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(postCSAgentChatStatisticsFailed(error.response));
        break;
      default:
    }
  }
}

function* postCSAgentChatStatisticsByIdSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    console.log("hello 2", params.payload)
    const response = yield call(postCSAgentChatStatisticsById, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(postCSAgentChatStatisticsByIdSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(postCSAgentChatStatisticsByIdFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(postCSAgentChatStatisticsByIdFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(postCSAgentChatStatisticsByIdFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(postCSAgentChatStatisticsByIdFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(postCSAgentChatStatisticsByIdFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(postCSAgentChatStatisticsByIdFailed(error.response));
        break;
      default:
    }
  }
}

function* postCSAgentChatSummarySaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(getCSAgentChatStatisticsSummary, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(postCSAgentChatSummarySuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(postCSAgentChatSummaryFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(postCSAgentChatSummaryFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(postCSAgentChatSummaryFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(postCSAgentChatSummaryFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(postCSAgentChatSummaryFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(postCSAgentChatSummaryFailed(error.response));
        break;
      default:
    }
  }
}

function* postCSAgentChatMonitoringSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(postCSAgentChatMonitoring, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(postCSAgentChatMonitoringSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(postCSAgentChatMonitoringFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(postCSAgentChatSummaryFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(postCSAgentChatSummaryFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(postCSAgentChatSummaryFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(postCSAgentChatSummaryFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(postCSAgentChatSummaryFailed(error.response));
        break;
      default:
    }
  }
}

function* postCSAgentForceLogoutSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(postCSAgentForceLogout, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(postCSAgentForceLogoutSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(postCSAgentForceLogoutFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(postCSAgentForceLogoutFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(postCSAgentForceLogoutFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(postCSAgentForceLogoutFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(postCSAgentForceLogoutFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(postCSAgentForceLogoutFailed(error.response));
        break;
      default:
    }
  }
}


function* postCSAgentHistorySaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    console.log("payload is", params.payload)
    const response = yield call(postCSAgentHistory, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(postCSAgentHistorySuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(postCSAgentHistoryFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(postCSAgentHistoryFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(postCSAgentHistoryFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(postCSAgentHistoryFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(postCSAgentHistoryFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(postCSAgentHistoryFailed(error.response));
        break;
      default:
    }
  }
}


export default [
  takeLatest(CONST.GET_MESSAGES, getMessagesSaga),
  takeLatest(CONST.GET_SELECTED_MESSAGE, getSelectedMessageSaga),
  takeLatest(CONST.POST_MESSAGE, postMessageSaga),
  takeLatest(CONST.PUT_READ_MESSAGE, putReadMessageSaga),
  takeLatest(CONST.TAKE_BY_CSAGENT, takeByCsagentSaga),
  takeLatest(CONST.CLOSE_CHAT_BY_CSAGENT, closeChatByCsagentSaga),
  takeLatest(CONST.UPLOAD_IMAGE, uploadImageSaga),
  takeLatest(CONST.POST_LIVE_CHAT_SETTINGS, postLiveChatSettingsSaga),
  takeLatest(CONST.GET_DETAIL_LIVE_CHAT_SETTING, getDetailLiveChatSettingSaga),
  takeLatest(CONST.PUT_DETAIL_LIVE_CHAT_SETTING, putDetailLiveChatSettingSaga),
  takeLatest(CONST.POST_CS_AGENT_STATISTIC, postCSAgentChatStatisticsSaga),
  takeLatest(CONST.POST_CS_AGENT_SUMMARY, postCSAgentChatSummarySaga),
  takeLatest(CONST.POST_CS_AGENT_MONITORING, postCSAgentChatMonitoringSaga),
  takeLatest(CONST.POST_CS_AGENT_FORCE_LOGOUT, postCSAgentForceLogoutSaga),
  takeLatest(CONST.POST_CS_AGENT_HISTORY, postCSAgentHistorySaga),
  takeLatest(CONST.POST_CS_AGENT_STATISTIC_BY_ID, postCSAgentChatStatisticsByIdSaga)

];
