import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import './styles.scss';
import { Alert, Container } from 'react-bootstrap';
const columns = [
  {
    dataField: '#',
    text: 'No',
    formatter: (row) => {
      return (
        <>
          {row.map((item, index) => (
            <div>{index + 1}</div>
          ))}
        </>
      );
    },
  },
  {
    dataField: '#',
    text: 'Pertanyaan',
  },
  {
    dataField: '#',
    text: 'Action',
    formatter: (row) => {
      return (
        <div className="container-button">
          {row?.data?.map((data, index) => (
            <div className="rounded">{index + 1}</div>
          ))}
        </div>
      );
    },
  },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  container: {
    maxHeight: 640,
  },
}));

function StoryBoard(props) {
  // const {} = props;
  //Helper
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Container>
      <Alert variant="danger">Page Sedang Dalam Maintenance</Alert>
    </Container>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(StoryBoard);
