import { makeStyles } from '@material-ui/core';

const Styles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
    height: '100px',
    boxShadow: '3px 3px 3px #9E9E9E',
    borderBottomRightRadius: '5px',
    borderBottomLeftRadius: '5px',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(6),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  container: {
    backgroundColor: '#FFFFFF',
    padding: '0px 50px 10px 50px',
    borderRadius: '10px',
    boxShadow: '1px 3px 3px #9E9E9E',
  },
}));

export default Styles;
