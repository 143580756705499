export const authInitialState = {
  getLoginParams: {},
  getLoginResponse: [],
  getLoginFetch: false,
  getLoginError: {
    message: {},
  },
};

export const sessionState = {
  createdAt: '',
  modifiedAt: '',
  id: 6,
  username: 'superadmin',
  handphone: '081222301298',
  email: '',
  name: 'Superadmin',
  active: false,
  referralCode: null,
  address: 'Bandung',
  longitude: null,
  latitude: null,
  clusterCode: null,
  cityCode: null,
  profileImage: null,
  authToken: null,
  customerId: null,
  resetPasswordToken: null,
  phoneVerified: true,
  emailVerified: true,
  token: '',
  userPermissions: [],
  role: {}
};
