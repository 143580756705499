import React, { useEffect, useRef, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
} from 'reactstrap';
import { DropzoneArea } from 'material-ui-dropzone';
import { Snackbar } from '@material-ui/core'; 
import Alert from '@material-ui/lab/Alert';

const SendImagePopUp = ({isOpen, handleClose, handleUploadImage, handleSave}) => {
    const [image, setImage] = useState();

    return (
        <Modal show={isOpen} onHide={handleClose} className="modal-data">
            <Modal.Header>
                <Modal.Title>Attach Image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Snackbar
                        // open={openErrorInfo}
                        autoHideDuration={4000}
                        // onClose={closeToast}
                        >
                        <Alert
                            // onClose={closeToast}
                            severity="error"
                            sx={{ width: '100%' }}>
                            {/* {errorInfo} */}
                        </Alert>
                    </Snackbar>
                    <div className="dropZone">
                        <DropzoneArea
                        filesLimit={1}
                        maxFileSize={3000000}
                        onChange={handleUploadImage}
                        acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                        dropzoneText={
                            'Drag and drop gambar atau klik disini untuk mengupload'
                        }
                        showAlerts={false}
                        showFileNames={true}
                        // key={key}
                        />
                    </div>
                    </Form.Group>
                </Form>
            </Modal.Body>
            {/* </CardBody> */}
            {/* <CardFooter> */}
            <Modal.Footer>
                <Button variant="primary" onClick={() => handleSave()}>
                    Confirm
                </Button>
            </Modal.Footer>
            {/* </CardFooter> */}
        {/* </Card> */}
        </Modal>
    )
}

export default SendImagePopUp;