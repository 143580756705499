import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
    container: {
        maxWidth: '80vw',
        height: '40vw',
        backgroundColor: '#E4E4E4',
        alignSelf: 'center',
        marginLeft: '3vw',
        flexDirection: 'row',
        overflow: 'auto',
        borderRadius: 15
    },
    chatContainer: {
        width: '100%',
        backgroundColor: '#E4E4E4',
        justifyContent: 'flex-end'
    },
    chatBoxHeader: {
        height: '4vw'
    },
    textFieldContainer: {
        backgroundColor: 'white',
        padding: 10,
        flexDirection: 'row',
        width: '100%'
    },
    textField: {
        marginRight: 10
    },
    buttonChatBox: {
        padding: 10,
        marginLeft: 10,
        width: '5%',
        alignSelf: 'center'
    },
    tableContainer: {
        borderWidth: 1,
        borderColor: '#C6C6C6',
        maxWidth: '20vw',
        height: '100%'
    },
    inboxTitleWrapper: {
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'space-between'
    },
    selectedTableCell: {
        backgroundColor: '#9C27B0'
    },
    readTableCell: {
        backgroundColor: '#EFEEEE'
    },
    selectedText: {
        color: 'white'
    },
    button: {
        width: '100%',
    },
    iconButton: {
        backgroundColor: '#9C27B0',
        padding: 10,
        marginLeft: 10,
        color: 'white'
    },
    floatingMenu: {
        right: 20,
        bottom: 20, 
        position: 'absolute'
    },
    mainButton: {
        backgroundColor: '#9C27B0'
    },
    mainButtonIcon: {
        color: 'white',
        fontSize: 20
    },
    childButton: {
        backgroundColor: 'white'
    },
    childButtonIcon: {
        color: '#9C27B0',
        fontSize: 20
    },
    chatCardMessageBox: {
        flexWrap: 'wrap', 
        maxWidth: 350, 
        padding: 10, 
        borderRadius: 5, 
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.05)' 
    },
    chatCardWhite: {
        backgroundColor: 'white', 
    },
    chatCardGrey: {
        backgroundColor: '#c9c9c9', 
    },
    chatCardDateText: {
        marginLeft: 5
    },
    chatCardSenderWrapper: {
        display: 'flex', 
        flexDirection: 'row',
    },
    chatCardContainerSender: {
        marginBottom: 10,
        alignItems: 'flex-end',
        display: 'flex',
        flexDirection: 'column',
    },
    chatCardContainer: {
        marginBottom: 10,
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
    },
    whiteText: {
        color: 'white',
    }, 
    circularContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 15
    },
    chatBoxContainer: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
    }, 
      
}))