export const SET_AUTH = 'SET_AUTH';
export const CLEAR_AUTH = 'CLEAR_AUTH';

export const REQUEST_AUTHENTICATION = 'REQUEST_AUTHENTICATION';
export const REQUEST_AUTHENTICATION_SUCCESS = 'REQUEST_AUTHENTICATION_SUCCESS';
export const REQUEST_AUTHENTICATION_FAILED = 'REQUEST_AUTHENTICATION_FAILED';

export const DO_LOGOUT = 'DO_LOGOUT';
export const DO_LOGOUT_SUCCESS = 'DO_LOGOUT_SUCCESS';
export const DO_LOGOUT_FAILED = 'DO_LOGOUT_FAILED';
