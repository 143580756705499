import React from 'react';
import { makeStyles, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Dashboard, ShoppingCart, Build, ChromeReaderMode, Stars, BarChart, AddBox } from '@material-ui/icons';

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  itemIcon: {
    padding: '0 8px',
  }
}));

const Sidebar = ({name}) => {
  const classes = useStyles();

  return (
    <List>
      <ListItemLink href="/" button selected={name === "Dashboard"}>
        <ListItemIcon className={classes.itemIcon}>
          <Dashboard />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemLink >

      <ListItemLink href="/awards" button selected={name === "Awards"}>
        <ListItemIcon className={classes.itemIcon}>
          <Stars />
        </ListItemIcon>
        <ListItemText primary="Awards" />
      </ListItemLink >

      <ListItemLink href="/products" button selected={name === "Products"}>
        <ListItemIcon className={classes.itemIcon}>
          <ShoppingCart />
        </ListItemIcon>
        <ListItemText primary="Products" />
      </ListItemLink >

      <ListItemLink button>
        <ListItemIcon className={classes.itemIcon}>
          <BarChart />
        </ListItemIcon>
        <ListItemText primary="Plan / Package" />
      </ListItemLink >

      <ListItemLink button>
        <ListItemIcon className={classes.itemIcon}>
          <ChromeReaderMode />
        </ListItemIcon>
        <ListItemText primary="Kuis Storyboard" />
      </ListItemLink >

      <ListItemLink button>
        <ListItemIcon className={classes.itemIcon}>
          <Build />
        </ListItemIcon>
        <ListItemText primary="Troubleshooting" />
      </ListItemLink >
    </List>
  )
}

export default Sidebar;