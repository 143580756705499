import React from 'react';
import Main from '@myrepublic-components/templates/main';
import { Link, Typography, Box } from '@material-ui/core';
import TablePagination from '@myrepublic-components/DataTable';
import { Alert, Container } from 'react-bootstrap';

export default function Dashboard(props) {
  return (
    <Container>
      <Alert variant="danger">Page Sedang Dalam Maintenance</Alert>
    </Container>
  );
}
