import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';
import {
  postProduct as postProductProps,
  uploadImage as uploadImageProps,
} from '@myrepublic-products/productAction';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DropzoneArea } from 'material-ui-dropzone';
import { Snackbar, IconButton, Select, MenuItem, FormControl } from '@material-ui/core';
import { Delete} from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import { values } from 'lodash';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { useDebounce } from 'use-debounce';
import { toast } from 'react-toastify';

function CreateProductTv(props) {
  const {
    postProduct,
    postProductResponse,
    uploadImage,
    uploadImageResponse,
    uploadImageFetch,
    uploadImageError,
    icon,
  } = props;
  const [data, setData] = useState({
    name: '',
    code: '',
    icon: '',
    description: '',
    addOnItemList: [],
    tv: true,
  });
  const [openErrorInfo, setOpenErrorInfo] = useState(false);
  const [errorInfo, setErrorInfo] = useState('');
  const [item, setItem] = useState({ data: [] });
  const [itemList] = useState([]);
  const [itemPrice] = useState([]);
  const ref = useRef(1);
  const history = useHistory();
  const [key, setKey] = useState(0);
  const [debounceKey] = useDebounce(key, 1000);
  const [selectedFile, setSelectedFile] = useState(null);

  const onChangeDescription = (e) => {
    setData({ ...data, description: e });
  };

  const billingCycleOptions = [
    {value: "initial", placeholder:"Select Billing Cycle"},
    {value: "MONTHLY", placeholder:"Bulanan"},
    {value: "WEEKLY", placeholder:"Mingguan"},
    {value: "YEARLY", placeholder:"Tahunan"},
  ]

  let closeToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenErrorInfo(false);
  };

  const dataVal = () => {
    const value = item.data;
    let data = [];
    if (typeof value === 'object') {
      data = value.map((product, index) => {
        if (product.id === undefined) {
          return {
            name: product.name,
            price: String(product.price),
            billingCycle: product.billingCycle
          }
        }
        return {
          id: product.id,
          name: product.name,
          price: String(product.price),
          billingCycle: product.billingCycle
        };
      });
      return values(data);
    }
  };

  const handleOpenErrorInfo = () => {
    setOpenErrorInfo(true);
  };

  const validateCreateForm = () => {
    let addOnItemList = dataVal();
    if (data?.name === '') {
      setErrorInfo('Nama Tv harus diisi');
      handleOpenErrorInfo();
      return false;
    } else if (data?.code === '') {
      setErrorInfo('Kode harus diisi');
      handleOpenErrorInfo();
      return false;
    } else if (data?.icon === '') {
      setErrorInfo('Gambar harus diupload');
      handleOpenErrorInfo();
      return false;
    } else if (data?.description === '') {
      setErrorInfo('Deskripsi harus diisi');
      handleOpenErrorInfo();
      return false;
    } else if (addOnItemList?.length === 0) {
      setErrorInfo('Item list harus diisi');
      handleOpenErrorInfo();
      return false;
    } else {
      addOnItemList.map((items) => {
        if(items.name === undefined || items.price === "undefined" || items.billingCycle === undefined){
          setErrorInfo('Lengkapi semua field data pada item list');
          handleOpenErrorInfo();
          return false;
        }
      })
    }
    return true;
  };

  const handleOnSave = () => {
    if (validateCreateForm() === true) {
      postProduct({
        ...data,
        addOnItemList: dataVal(),
      });
    }
  };

  const onUploadImage = (image) => {
    if (image?.length > 0) {
      uploadImage({ data: { image: image[0], type: 'PRODUCT' } });
    }
  };

  useEffect(() => {
    if (postProductResponse?.code === 200) {
      toast.success(postProductResponse?.message);
      setTimeout(() => {
        history.replace('/productsTv');
      }, 3000);
    }
  }, [postProductResponse]);

  useEffect(() => {
    setItem({ data: [...item.data, {name: undefined, price: undefined}] });
  }, []);

  useEffect(() => {
    if (uploadImageResponse?.url !== undefined) {
      setData({ ...data, icon: uploadImageResponse?.url });
    }
  }, [uploadImageResponse]);

  useEffect(() => {
    if (uploadImageError?.error !== undefined && uploadImageError?.message) {
      setErrorInfo(uploadImageError?.message);
      handleOpenErrorInfo();
      setKey();
    }
  }, [uploadImageError]);

  const ItemListComponent = () => {
    
    const addList = () => {
      let list = parseInt(item?.data.length);
      setItem({ data: [...item.data, {name: undefined, price: undefined}] });
    };

    const onChange = (e, index) => {
      const { name, value } = e.target
      const newArray = [...item.data]
      newArray[index][name] = value
      setItem({data: newArray})
    }

    const onChangeBillingCycle = (e, index) => {
      const { value } = e.target
      if(value !== "initial"){
        const newArray = [...item.data]
        newArray[index]["billingCycle"] = value
        setItem({data: newArray})
      }
    }

    const deleteHandler = async (id, index) => {
      const tempItem = item.data.splice(index, 1);
      setItem({data: item.data});
    }

    return (
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Item List</Form.Label>
        <div className="d-flex justify-content-end">
          <div
            style={{
              width: '130px',
              borderRadius: '5px',
              height: '20px',
              backgroundColor: '#9c27b0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              color: 'white',
              fontWeight: 'bold',
            }}
            onClick={addList}>
            Tambah Item +
          </div>
        </div>
        {item?.data.map((i, index) => {
          return (
            <Row key={index}>
              <Col className="mt-2 mx-6 d-flex justify-content-end">
              <Form.Control
                  className={'sm-6'}
                  type="text"
                  placeholder={'Nama item'}
                  value={i.name}
                  name="name"
                  onChange={(e) => onChange(e, index)}
                />
              </Col>
              <Col className="mt-2 mx-6 d-flex justify-content-end">
                <Form.Control
                  type="number"
                  name="price"
                  placeholder={'Harga'}
                  value={i.price}
                  onChange={(e) => onChange(e, index)}
                />
              </Col>
              <Col className="mt-2 mx-6 d-flex justify-content-end">
              <FormControl fullWidth>
                <Select
                  variant='outlined'
                  value={i?.billingCycle ? i.billingCycle : "initial"}
                  label="billingCycle"
                  onChange={(e) => onChangeBillingCycle(e, index)}
                  style={{marginTop: 5}}
                >
                  {
                    billingCycleOptions.map((option) => (
                      <MenuItem value={option.value}>{option.placeholder}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
              </Col>
              <Col className="mt-2 mx-2 d-flex justify-content-center" xs="1">
                <IconButton color="secondary" onClick={() => deleteHandler(i.id, index)}>
                    <Delete />
                </IconButton>
              </Col>
            </Row>
          );
        })}
      </Form.Group>
    );
  };

  return (
    <Card className="mx-4 card-box">
      <CardHeader>
        <CardTitle>Tambah Tv</CardTitle>
      </CardHeader>
      <CardBody className="p-3">
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Tv</Form.Label>
            <Row className="mb-3">
              <Col className="mt-2 mx-6 d-flex justify-content-end">
                <Form.Control
                  placeholder={'Nama Tv'}
                  type="text"
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                />
              </Col>
              <Col className="mt-2 mx-6 d-flex justify-content-end">
                <Form.Control
                  type="text"
                  placeholder={'Kode'}
                  onChange={(e) => setData({ ...data, code: e.target.value })}
                />
              </Col>
            </Row>
            <Form.Label>Gambar</Form.Label>
            <Snackbar
              open={openErrorInfo}
              autoHideDuration={4000}
              onClose={closeToast}>
              <Alert
                onClose={closeToast}
                severity="error"
                sx={{ width: '100%' }}>
                {errorInfo}
              </Alert>
            </Snackbar>
            <div className="dropZone">
              <DropzoneArea
                filesLimit={1}
                maxFileSize={3000000}
                onChange={onUploadImage}
                acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                dropzoneText={
                  'Drag and drop gambar atau klik disini untuk mengupload'
                }
                showAlerts={false}
                showFileNames={true}
                // key={key}
              />
            </div>
          </Form.Group>

          {ItemListComponent()}
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Deskripsi</Form.Label>
            <CKEditor
              editor={ClassicEditor}
              data=""
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                // console.log('Editor is ready to use!', editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                // console.log('Editor event data');
                // setData({ ...data, description: data });
                onChangeDescription(data);
              }}
              onBlur={(event, editor) => {
                // console.log('Blur.', editor);
              }}
              onFocus={(event, editor) => {
                // console.log('Focus.', editor);
              }}
            />
          </Form.Group>
        </Form>
      </CardBody>
      <CardFooter>
        <Button variant="primary" onClick={handleOnSave}>
          Simpan
        </Button>
      </CardFooter>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  postProductResponse: state.product.postProductResponse,
  uploadImageResponse: state.product.uploadImageResponse,
  uploadImageError: state.product.uploadImageError,
  icon: state.product.icon,
});
const mapDispatchToProps = {
  postProduct: (payload) => postProductProps(payload),
  uploadImage: (payload) => uploadImageProps(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateProductTv);
