import * as STATE from '@myrepublic-auth/authInitialState';
import * as CONST from '@myrepublic-auth/authConstant';

const authState = {
  ...STATE.authInitialState,
  action: '',
};

const sessionState = {
  ...STATE.sessionState,
  action: '',
};
export const loginReducer = (state = authState, action) => {
  const { payload, type } = action;
  const actions = {
    [CONST.REQUEST_AUTHENTICATION]: () => ({
      ...state,
      getLoginFetch: true,
      getLoginParams: payload,
      action: type,
    }),
    [CONST.REQUEST_AUTHENTICATION_SUCCESS]: () => ({
      ...state,
      getLoginFetch: false,
      getLoginResponse: payload,
      action: type,
    }),
    [CONST.REQUEST_AUTHENTICATION_FAILED]: () => ({
      ...state,
      getLoginFetch: false,
      getLoginError: payload,
      action: type,
    }),
    
    [CONST.DO_LOGOUT]: () => ({
      ...state,
      doLogoutFetch: true,
      doLogoutParams: payload,
      action: type,
    }),
    [CONST.DO_LOGOUT_SUCCESS]: () => ({
      ...state,
      doLogoutFetch: false,
      doLogoutResponse: payload,
      action: type,
    }),
    [CONST.DO_LOGOUT_FAILED]: () => ({
      ...state,
      doLogoutFetch: false,
      doLogoutError: payload,
      action: type,
    }),
    DEFAULT: () => state,
  };
  return (actions[type] || actions.DEFAULT)();
};

export const sessionReducer = (state = sessionState, action) => {
  const { payload, type } = action;
  const actions = {
    [CONST.SET_AUTH]: () => ({
      ...state,
      ...payload.user,
      token: payload?.token,
      action: type,
    }),
    [CONST.CLEAR_AUTH]: () => ({
      token: payload,
      action: type,
    }),
    DEFAULT: () => state,
  };
  return (actions[type] || actions.DEFAULT)();
};
