import * as CONST from '@myrepublic-auth/authConstant';

export const getLogin = (payload) => ({
  type: CONST.REQUEST_AUTHENTICATION,
  payload,
});

export const getLoginSuccess = (payload) => ({
  type: CONST.REQUEST_AUTHENTICATION_SUCCESS,
  payload,
});

export const getLoginFailed = (payload) => ({
  type: CONST.REQUEST_AUTHENTICATION_FAILED,
  payload,
});

export const setAuth = (payload) => ({ type: CONST.SET_AUTH, payload });
export const clearAuth = (payload) => ({ type: CONST.CLEAR_AUTH, payload });

export const doLogout = (payload) => ({
  type: CONST.DO_LOGOUT,
  payload,
});

export const doLogoutSuccess = (payload) => ({
  type: CONST.DO_LOGOUT_SUCCESS,
  payload,
});

export const doLogoutFailed = (payload) => ({
  type: CONST.DO_LOGOUT_FAILED,
  payload,
});