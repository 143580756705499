import React from 'react';

import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CreateIcon from '@material-ui/icons/Create';

const actions = [
  {
    title: 'update',
    icon: <CreateIcon />,
  },
  {
    title: 'delete',
    icon: <DeleteOutlineIcon />,
  },
];
export const answerColumn = (toogleModal) => [
  {
    dataField: '#',
    text: 'ID TROUBLESHOOTING',
    headerStyle: { width: '15vw' },
    style: { verticalAlign: 'middle' },
    formatter: (cell, row, rowIndex) => {
      return row?.troubleshootingId;
    },
  },
  {
    dataField: '#',
    text: 'Answer Data',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
    formatter: (cell, row) => {
      return <label className="text-align-center"> {row.title} </label>;
    },
  },
  {
    dataField: '#',
    text: 'Action',
    headerStyle: { textAlign: 'center' },
    formatter: (cell, row) => {
      return (
        <div className="container-button">
          {actions?.map((data, index) => (
            <div className="rounded" onClick={() => toogleModal(row, data)}>
              {data?.icon}
            </div>
          ))}
        </div>
      );
    },
  },
];
