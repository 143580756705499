export const GET_VENDOR_SUCCESS = 'GET_VENDOR_SUCCESS';
export const GET_VENDOR = 'GET_VENDOR';
export const GET_VENDOR_FAILED = 'GET_VENDOR_FAILED';

export const GET_WORK_ORDER_SUCCESS = 'GET_WORK_ORDER_SUCCESS';
export const GET_WORK_ORDER = 'GET_WORK_ORDER';
export const GET_WORK_ORDER_FAILED = 'GET_WORK_ORDER_FAILED';

export const POST_WORK_ORDER_SUCCESS = 'POST_WORK_ORDER_SUCCESS';
export const POST_WORK_ORDER = 'POST_WORK_ORDER';
export const POST_WORK_ORDER_FAILED = 'POST_WORK_ORDER_FAILED';

export const PUT_VENDOR_STATUS_SUCCESS = 'PUT_VENDOR_STATUS_SUCCESS';
export const PUT_VENDOR_STATUS = 'PUT_VENDOR_STATUS';
export const PUT_VENDOR_STATUS_FAILED = 'PUT_VENDOR_STATUS_FAILED';

export const POST_VENDOR_SUCCESS = 'POST_VENDOR_SUCCESS';
export const POST_VENDOR = 'POST_VENDOR';
export const POST_VENDOR_FAILED = 'POST_VENDOR_FAILED';

export const POST_VENDOR_STATISTIC_SUCCESS = 'POST_VENDOR_STATISTIC_SUCCESS';
export const POST_VENDOR_STATISTIC = 'POST_VENDOR_STATISTIC';
export const POST_VENDOR_STATISTIC_FAILED = 'POST_VENDOR_STATISTIC_FAILED';

export const GET_VENDOR_DETAIL_SUCCESS = 'GET_VENDOR_DETAIL_SUCCESS';
export const GET_VENDOR_DETAIL = 'GET_VENDOR_DETAIL';
export const GET_VENDOR_DETAIL_FAILED = 'GET_VENDOR_DETAIL_FAILED';

export const PUT_VENDOR_SUCCESS = 'PUT_VENDOR_SUCCESS';
export const PUT_VENDOR = 'PUT_VENDOR';
export const PUT_VENDOR_FAILED = 'PUT_VENDOR_FAILED';

export const RESET_STATE = 'RESET_STATE';