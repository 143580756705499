import * as CONST from '@myrepublic-troubleshooting/troubleShootingConstant';

export const getDataTrouble = (payload) => ({
  type: CONST.GET_DATA_TROUBLE,
  payload,
});

export const getDataTroubleSuccess = (payload) => ({
  type: CONST.GET_DATA_TROUBLE_SUCCESS,
  payload,
});

export const getDataTroubleFailed = (payload) => ({
  type: CONST.GET_DATA_TROUBLE_FAILED,
  payload,
});

export const createDataTrouble = (payload) => ({
  type: CONST.CREATE_DATA_TROUBLE,
  payload,
});

export const createDataTroubleSuccess = (payload) => ({
  type: CONST.CREATE_DATA_TROUBLE_SUCCESS,
  payload,
});

export const createDataTroubleFailed = (payload) => ({
  type: CONST.CREATE_DATA_TROUBLE_FAILED,
  payload,
});

export const deleteDataTrouble = (payload) => ({
  type: CONST.DELETE_DATA_TROUBLE,
  payload,
});

export const deleteDataTroubleSuccess = (payload) => ({
  type: CONST.DELETE_DATA_TROUBLE_SUCCESS,
  payload,
});

export const deleteDataTroubleFailed = (payload) => ({
  type: CONST.DELETE_DATA_TROUBLE_FAILED,
  payload,
});

export const answerDataTrouble = (payload) => ({
  type: CONST.ANSWER_DATA_TROUBLE,
  payload,
});

export const answerDataTroubleSuccess = (payload) => ({
  type: CONST.ANSWER_DATA_TROUBLE_SUCCESS,
  payload,
});

export const answerDataTroubleFailed = (payload) => ({
  type: CONST.ANSWER_DATA_TROUBLE_FAILED,
  payload,
});

export const putDataTrouble = (payload) => ({
  type: CONST.PUT_DATA_TROUBLE,
  payload,
});

export const putDataTroubleSuccess = (payload) => ({
  type: CONST.PUT_DATA_TROUBLE_SUCCESS,
  payload,
});

export const putDataTroubleFailed = (payload) => ({
  type: CONST.PUT_DATA_TROUBLE_FAILED,
  payload,
});

export const deleteAnswerDataTrouble = (payload) => ({
  type: CONST.DELETE_ANSWER_DATA_TROUBLE,
  payload,
});

export const deleteAnswerDataTroubleSuccess = (payload) => ({
  type: CONST.DELETE_ANSWER_DATA_TROUBLE_SUCCESS,
  payload,
});

export const deleteAnswerDataTroubleFailed = (payload) => ({
  type: CONST.DELETE_ANSWER_DATA_TROUBLE_FAILED,
  payload,
});

export const getAnswerTrouble = (payload) => ({
  type: CONST.GET_ANSWER_TROUBLE,
  payload,
});

export const getAnswerTroubleSuccess = (payload) => ({
  type: CONST.GET_ANSWER_TROUBLE_SUCCESS,
  payload,
});

export const getAnswerTroubleFailed = (payload) => ({
  type: CONST.GET_ANSWER_TROUBLE_FAILED,
  payload,
});

export const putAnswerTrouble = (payload) => ({
  type: CONST.PUT_ANSWER_TROUBLE,
  payload,
});

export const putAnswerTroubleSuccess = (payload) => ({
  type: CONST.PUT_ANSWER_TROUBLE_SUCCESS,
  payload,
});

export const putAnswerTroubleFailed = (payload) => ({
  type: CONST.PUT_ANSWER_TROUBLE_FAILED,
  payload,
});

export const resetAnswerData =  (payload) =>({
  type: CONST.RESET_ANSWER,
  payload,
});
