import { apiAuth } from '@myrepublic-bootstrap/bootstrapApi';

export const postLoginApi = (payload) => {
  return apiAuth.post(`/api/v1/auth/login`, payload);
};

export const doLogoutApi = (payload) => {
  const { accessToken, ...data } = payload;
  apiAuth.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return apiAuth.get(`/api/v1/web/dashboard/logout`, data);
};

