import React, { useEffect, useState, useCallback } from 'react';
import useStyles from './styles';
import { connect } from 'react-redux';
import { Card } from 'react-bootstrap';
import {
  getMessages as getMessagesProps,
  getSelectedMessage as getSelectedMessageProps,
  takeByCsagent as takeByCsagentProps,
  closeChatByCsagent as closeChatByCsagentProps,
  postMessage as postMessageProps,
  uploadImage as uploadImageProps,
} from '@myrepublic-livechat/liveChatAction';
import { setFirebaseData as setFirebaseDataProps } from '@myrepublic-bootstrap/bootstrapAction';
import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from 'react-floating-button-menu';
import { Add, Clear, Refresh, Send, ChatBubble } from '@material-ui/icons';
import TableInbox from './TableInbox';
import Chat from './Chat';
import SendImagePopUp from './SendImagePopUp';

function LiveChat(props) {
  const {
    getMessages,
    getMessagesResponse,
    getSelectedMessage,
    getSelectedMessageResponse,
    postMessage,
    postMessageResponse,
    firebaseData,
    takeByCsagent,
    takeByCsagentResponse,
    closeChatByCsagent,
    uploadImage,
    uploadImageResponse,
    uploadImageFetch,
    uploadImageError,
    setFirebaseData,
  } = props;

  const classes = useStyles();

  const [selected, setSelected] = useState();
  const [senderId, setSenderId] = useState();
  const [message, setMessage] = useState();
  const [messageList, setMessageList] = useState();
  const [selectedMessageList, setSelectedMessageList] = useState();
  const [imagePopUp, setImagePopUp] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
    if (uploadImageResponse?.url !== undefined) {
      console.log('uploaded', uploadImageResponse?.url);
      setImageUrl(uploadImageResponse?.url);
    }
  }, [uploadImageResponse]);

  // useEffect(() => {
  //   if (uploadImageError?.error !== undefined && uploadImageError?.message) {
  //     setErrorInfo(uploadImageError?.message);
  //     handleOpenErrorInfo();
  //     setKey();
  //   }
  // }, [uploadImageError]);

  const onSelect = (id, senderId) => {
    setSelected(id);
    setSenderId(senderId);
    setSelectedMessageList();
    getSelectedMessage({
      id: senderId,
    });
    takeByCsagent({
      id: id,
    });
  };

  const handleUploadImage = (image) => {
    console.log('on save image', image);
    if (image?.length > 0) {
      uploadImage({ data: { image: image[0], type: 'CHAT_ATTACHMENT' } });
    }
  };

  const onSendImage = () => {
    postMessage({
      title: 'Reply from admin',
      message: 'image',
      recipientId: senderId,
      chatType: 'PHOTO',
      url: imageUrl,
      latitude: '',
      longitude: '',
    });
    setImagePopUp(false);
    setImageUrl();
  };

  const onSend = async () => {
    if (message != null && selected != null) {
      // await setSelectedMessageList([
      //   ...selectedMessageList,
      //   { message: message },
      // ]);
      postMessage({
        title: 'Reply from admin',
        message: message,
        recipientId: senderId,
        chatType: 'TEXT',
        url: '',
        latitude: '',
        longitude: '',
      });
      setMessage('');
    }
  };

  const closeChat = async () => {
    setFirebaseData({});
    closeChatByCsagent({
      id: selected,
    });
    setTimeout(() => {
      onRefresh();
    }, 1000);
  };

  const onRefresh = () => {
    setSelected();
    setSenderId();
    setSelectedMessageList();
    getMessages({
      limit: 10,
      offset: 0,
      filter: {},
    });
  };

  useEffect(() => {
    setSelectedMessageList();
    getMessages({
      limit: 10,
      offset: 0,
      filter: {},
    });
  }, []);

  useEffect(() => {
    setMessageList(getMessagesResponse?.data);
  }, [getMessagesResponse]);

  useEffect(() => {
    if (senderId !== undefined)
      setSelectedMessageList(getSelectedMessageResponse);
  }, [getSelectedMessageResponse]);

  useEffect(() => {
    if (senderId !== undefined) {
      getSelectedMessage({ id: senderId });

      getMessages({
        limit: 10,
        offset: 0,
        filter: {},
      });
    }
  }, [postMessageResponse]);

  useEffect(() => {
    if (firebaseData?.title !== undefined) {
      if (senderId !== undefined) {
        getSelectedMessage({ id: senderId });
      }
    }
  }, [firebaseData, senderId]);

  return (
    <>
      <Card className={classes.container}>
        <TableInbox
          data={messageList}
          selected={selected}
          setSelected={onSelect}
          onRefresh={onRefresh}
        />
        <Chat
          data={selectedMessageList}
          setMessage={(e) => setMessage(e.target.value)}
          onSend={onSend}
          isTextFieldActive={selected == null ? true : false}
          senderId={senderId}
          setImagePopUp={() => setImagePopUp(true)}
          closeChat={closeChat}
        />
        <SendImagePopUp
          isOpen={imagePopUp}
          handleClose={() => setImagePopUp(false)}
        />
        <SendImagePopUp
          isOpen={imagePopUp}
          handleClose={() => setImagePopUp(false)}
          handleUploadImage={handleUploadImage}
          handleSave={onSendImage}
        />
      </Card>
      {/*<FloatingMenus open={open} setOpen={setOpen} />*/}
    </>
  );
}

const FloatingMenus = ({ open, setOpen }) => {
  const classes = useStyles();

  return (
    <FloatingMenu
      slideSpeed={500}
      direction="up"
      spacing={8}
      isOpen={open}
      className={classes.floatingMenu}>
      <MainButton
        iconResting={<Add className={classes.mainButtonIcon} />}
        iconActive={<Clear className={classes.mainButtonIcon} />}
        className={classes.mainButton}
        onClick={() => setOpen(!open)}
        size={56}
      />
      <ChildButton
        icon={<Refresh className={classes.childButtonIcon} />}
        size={40}
        className={classes.childButton}
        onClick={() => console.log('First button clicked')}
      />
      <ChildButton
        icon={<Send className={classes.childButtonIcon} />}
        className={classes.childButton}
        size={40}
      />
      <ChildButton
        icon={<ChatBubble className={classes.childButtonIcon} />}
        className={classes.childButton}
        size={40}
      />
    </FloatingMenu>
  );
};

const mapStateToProps = (state) => ({
  getMessagesResponse: state.livechat.getMessagesResponse,
  getSelectedMessageResponse: state.livechat.getSelectedMessageResponse,
  postMessageResponse: state.livechat.postMessageResponse,
  firebaseData: state.bootstrap.firebaseData,
  takeByCsagentResponse: state.livechat.takeByCsagentResponse,
  uploadImageResponse: state.award.uploadImageResponse,
  uploadImageError: state.award.uploadImageError,
});

const mapDispatchToProps = {
  getMessages: (payload) => getMessagesProps(payload),
  getSelectedMessage: (payload) => getSelectedMessageProps(payload),
  postMessage: (payload) => postMessageProps(payload),
  setFirebaseData: (payload) => setFirebaseDataProps(payload),
  takeByCsagent: (payload) => takeByCsagentProps(payload),
  closeChatByCsagent: (payload) => closeChatByCsagentProps(payload),
  uploadImage: (payload) => uploadImageProps(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveChat);
