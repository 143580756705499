import * as CONST from '@myrepublic-products/productConstant';

export const getProduct = (payload) => ({
  type: CONST.GET_PRODUCTS,
  payload,
});

export const getProductSuccess = (payload) => ({
  type: CONST.GET_PRODUCTS_SUCCESS,
  payload,
});

export const getProductFailed = (payload) => ({
  type: CONST.POST_PRODUCTS_FAILED,
  payload,
});
export const getProductTv = (payload) => ({
  type: CONST.GET_PRODUCTS_TV,
  payload,
});

export const getProductTvSuccess = (payload) => ({
  type: CONST.GET_PRODUCTS_TV_SUCCESS,
  payload,
});

export const getProductTvFailed = (payload) => ({
  type: CONST.GET_PRODUCTS_TV_FAILED,
  payload,
});

export const postProduct = (payload) => ({
  type: CONST.POST_PRODUCTS,
  payload,
});

export const postProductSuccess = (payload) => ({
  type: CONST.POST_PRODUCTS_SUCCESS,
  payload,
});

export const postProductFailed = (payload) => ({
  type: CONST.UPDATE_PRODUCTS_FAILED,
  payload,
});

export const putProduct = (payload) => ({
  type: CONST.UPDATE_PRODUCTS,
  payload,
});

export const putProductSuccess = (payload) => ({
  type: CONST.UPDATE_PRODUCTS_SUCCESS,
  payload,
});

export const putProductFailed = (payload) => ({
  type: CONST.UPDATE_PRODUCTS_FAILED,
  payload,
});

export const deleteProduct = (payload) => ({
  type: CONST.DELETE_PRODUCTS,
  payload,
});

export const deleteProductSuccess = (payload) => ({
  type: CONST.DELETE_PRODUCT_SUCCESS,
  payload,
});

export const deleteProductFailed = (payload) => ({
  type: CONST.DELETE_PRODUCT_FAILED,
  payload,
});

export const deleteProductAddOn = (payload) => ({
  type: CONST.DELETE_PRODUCTS_ADD_ON,
  payload,
});

export const deleteProductAddOnSuccess = (payload) => ({
  type: CONST.DELETE_PRODUCT_ADD_ON_SUCCESS,
  payload,
});

export const deleteProductAddOnFailed = (payload) => ({
  type: CONST.DELETE_PRODUCT_ADD_ON_FAILED,
  payload,
});

export const detailProduct = (payload) => ({
  type: CONST.DETAIL_PRODUCTS,
  payload,
});

export const detailProductSuccess = (payload) => ({
  type: CONST.DETAIL_PRODUCT_SUCCESS,
  payload,
});

export const detailProductFailed = (payload) => ({
  type: CONST.DETAIL_PRODUCT_FAILED,
  payload,
});

export const uploadImage = (payload) => ({
  type: CONST.UPLOAD_IMAGE,
  payload,
});

export const uploadImageSuccess = (payload) => ({
  type: CONST.UPLOAD_IMAGE_SUCCESS,
  payload,
});

export const uploadImageFailed = (payload) => ({
  type: CONST.UPLOAD_IMAGE_FAILED,
  payload,
});

export const setImageUrl = (payload) => ({
  type: CONST.SET_IMAGE_URL,
  payload,
});

export const resetState = (payload) => ({
  type: CONST.RESET_STATE,
  payload,
});
