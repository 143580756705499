import { api } from '@myrepublic-bootstrap/bootstrapApi';

export const getVendor = (payload) => {
    const { accessToken, ...data } = payload;
    api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
    return api.post(`/api/v1/web/vendors`, data);
}

export const getWorkOrder = (payload) => {
    const { accessToken, ...data } = payload;
    api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
    return api.post(`/api/v1/web/woList`, data);
}

export const postWorkOrder = (payload) => {
    const { accessToken, ...data } = payload;
    console.log("data on create", data)
    api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
    return api.post(`/api/v1/web/woList/create`, data);
}

export const putVendorStatus = (payload) => {
    const { accessToken, ...data } = payload;
    api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
    return api.put(`api/v1/web/vendors/updateStatus/${data.id}`, data.body);
}

export const postVendor = (payload) => {
    const { accessToken, ...data } = payload;
    api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
    return api.post(`api/v1/web/vendors/create`, data);
}

export const postVendorStatistics = (payload) => {
    const { accessToken, ...data } = payload;
    api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
    return api.post(`api/v1/web/vendors/statistics`, data);
}

export const putVendor = (payload) => {
    const { accessToken, ...data } = payload;
    api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
    return api.put(`/api/v1/web/vendors/${data.id}`, data.body);
}

export const getVendorDetail = (payload) => {
    const { accessToken, ...data } = payload;
    console.log("get vendor data", data)
    api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
    return api.get(`/api/v1/web/vendors/detail/${data.id}`);
}