export const troubleInitialState = {
  getDataTroubleFetch: false,
  getDataTroubleParams: {},
  getDataTroubleResponse: [],
  getDataTroubleError: {
    message: '',
  },

  createDataTroubleFetch: false,
  createDataTroubleParams: {},
  createDataTroubleResponse: [],
  createDataTroubleError: {
    message: '',
  },

  deleteDataTroubleFetch: false,
  deleteDataTroubleParams: {},
  deleteDataTroubleResponse: [],
  deleteDataTroubleError: {
    message: '',
  },

  answerDataTroubleFetch: false,
  answerDataTroubleParams: {},
  answerDataTroubleResponse: [],
  answerDataTroubleError: {
    message: '',
  },

  putDataTroubleFetch: false,
  putDataTroubleParams: {},
  putDataTroubleResponse: [],
  putDataTroubleError: {
    message: '',
  },

  deleteAnswerDataTroubleFetch: false,
  deleteAnswerDataTroubleParams: {},
  deleteAnswerDataTroubleResponse: [],
  deleteAnswerDataTroubleError: {
    message: '',
  },

  getAnswerTroubleFetch: false,
  getAnswerTroubleParams: {},
  getAnswerTroubleResponse: [],
  getAnswerTroubleError: {
    message: '',
  },

  putAnswerTroubleFetch: false,
  putAnswerTroubleParams: {},
  putAnswerTroubleResponse: [],
  putAnswerTroubleError: {
    message: '',
  },
};
