import React, { useEffect, useState, createRef } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Styles from './styles';
import { forgotPassword as forgotPasswordProps } from '@myrepublic-forgotPassword/forgotPasswordAction';

function ForgotPassword(props) {
  const {
    forgotPassword,
    forgotPasswordResponse,
    forgotPasswordError,
    forgotPasswordFetch,
  } = props;
  const ref = createRef();
  const styles = Styles();
  const [inputs, setInputs] = useState({
    loginId: '',
  });
  const [submitted, setSubmitted] = useState(false);
  const [open, setOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  let closeToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  let handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  let validationForm = () => {
    if (inputs?.loginId !== '') {
      return true;
    }

    return false;
  };

  let handleSubmit = (e) => {
    e.preventDefault();

    setSubmitted(true);
    setOpen(false);
    setResponseMessage('');
    if (validationForm()) {
      const req = {
        loginId: inputs?.loginId,
      };
      forgotPassword(req);
    }
  };

  useEffect(() => {
    if (submitted && forgotPasswordError?.status !== undefined) {
      setOpen(true);
      setResponseMessage(
        forgotPasswordError?.status === 404 ? 'Akun tidak ditemukan' : ''
      );
    }
    if (submitted && forgotPasswordResponse.status !== undefined) {
      setOpen(true);
      setResponseMessage(
        forgotPasswordResponse?.code === 200
          ? forgotPasswordResponse?.message
          : ''
      );
    }
  }, [submitted, forgotPasswordError, forgotPasswordResponse]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(true);
      }, 3000);
    }
    if (responseMessage) {
      setTimeout(() => {
        setResponseMessage('');
      }, 3000);
    }
  }, [open, responseMessage]);
  return (
    <Container
      component="main"
      maxWidth="xs"
      className={styles.container}
      ref={ref}>
      <Snackbar
        open={open && responseMessage !== ''}
        autoHideDuration={4000}
        onClose={closeToast}>
        <Alert
          onClose={closeToast}
          severity={forgotPasswordResponse?.code === 200 ? 'success' : 'error'}>
          {responseMessage !== '' ? responseMessage : ''}
        </Alert>
      </Snackbar>
      <CssBaseline />
      <div className={styles.paper}>
        <img src="logo.png" className={styles.logo} />
        <form className={styles.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="loginId"
            label="Email"
            name="loginId"
            autoComplete="Email"
            size="small"
            onChange={handleChange}
            autoFocus
            error={submitted && !inputs?.loginId ? true : false}
            helperText={submitted && !inputs?.loginId ? 'Harus diisi' : ''}
          />
          <Button
            disabled={forgotPasswordFetch}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={styles.submit}>
            Submit
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/login" variant="body2">
                Login Kembali?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="#">
            My Republic
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Box>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  forgotPasswordResponse: state.forgotPassword.forgotPasswordResponse,
  forgotPasswordError: state.forgotPassword.forgotPasswordError,
  forgotPasswordFetch: state.forgotPassword.forgotPasswordFetch,
});

const mapDispatchToProps = {
  forgotPassword: (payload) => forgotPasswordProps(payload),
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
