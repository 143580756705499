import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';

let theme = createTheme({
    typography : {
        fontSize: 12,
    },

    palette : {
        primary : {
            main: purple[500],
        }
    }
});

// theme.palette = {
//     primary : {
//       main: purple[500],
//     },
//     secondary: {
//       main: green[500],
//     }
// };

theme = responsiveFontSizes(theme);

export default theme;