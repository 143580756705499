import * as CONST from '@myrepublic-resetPassword/resetPasswordConstant';
import * as STATE from '@myrepublic-resetPassword/resetPasswordInitialState';

const initialState = {
  ...STATE.resetPasswordInitialState,
  ...STATE.formResetPasswordInitialState,
  action: '',
};
export const resetPasswordReducer = (state = initialState, action) => {
  const { payload, type } = action;

  const actions = {
    [CONST.RESET_PASSWORD]: () => ({
      ...state,
      resetPasswordFetch: true,
      resetPasswordParams: payload,
      action: type,
    }),
    [CONST.RESET_PASSWORD_SUCCESS]: () => ({
      ...state,
      resetPasswordFetch: false,
      resetPasswordResponse: payload,
      action: type,
    }),
    [CONST.RESET_PASSWORD_FAILED]: () => ({
      ...state,
      resetPasswordFetch: false,
      resetPasswordError: payload,
      action: type,
    }),
    [CONST.FORM_RESET_PASSWORD]: () => ({
      ...state,
      formResetPasswordFetch: true,
      formResetPasswordParams: payload,
      action: type,
    }),
    [CONST.FORM_RESET_PASSWORD_SUCCESS]: () => ({
      ...state,
      formResetPasswordFetch: false,
      formResetPasswordResponse: payload,
      action: type,
    }),
    [CONST.FORM_RESET_PASSWORD_FAILED]: () => ({
      ...state,
      formResetPasswordFetch: false,
      formResetPasswordError: payload,
      action: type,
    }),

    DEFAULT: () => state,
  };
  return (actions[type] || actions.DEFAULT)();
};

export default resetPasswordReducer;
