import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';

import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { 
  getDetailLiveChatSetting as getDetailLiveChatSettingProps,
  putDetailLiveChatSetting as putDetailLiveChatSettingProps
} from '@myrepublic-livechat/liveChatAction'
// import { toast } from 'react-toastify';

function UpdateLivechatSetting(props) {
  const {
    getDetailLiveChatSetting, 
    getDetailLiveChatSettingResponse,
    putDetailLiveChatSetting,
    putDetailLiveChatSettingResponse,
    match: { params },
  } = props;
  const [data, setData] = useState({});

  // console.log('params is', params)

  useEffect(() => {
    getDetailLiveChatSetting({id: params.id});
  }, [])

  useEffect(() => {
    console.log('getDetailLiveChatSettingResponse', getDetailLiveChatSettingResponse)
    setData(getDetailLiveChatSettingResponse)
  }, [getDetailLiveChatSettingResponse])

  const handleUpdate = () => {
    console.log("HELLO")
    putDetailLiveChatSetting({id: params.id, payload: data})
  }

  return (
    <Card className="mx-4 card-box">
      <CardHeader>
        <CardTitle>Update Setting Livechat</CardTitle>
      </CardHeader>
      <CardBody className="p-3">
        <Form>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>App Name</Form.Label>
                <Form.Control
                  value={data?.appsName}
                  type="text"
                  placeholder="App Name"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>App Version</Form.Label>
                <Form.Control
                  value={data?.appsVersion}
                  type="text"
                  placeholder="App Version"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>URL Android</Form.Label>
                <Form.Control
                  value={data?.appsUrl1}
                  type="text"
                  placeholder="URL Android"
                //   onChange={(e) => setData({ ...data, name: e.target.value })}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>URL IOS</Form.Label>
                <Form.Control
                  value={data?.appsUrl2}
                  type="text"
                  placeholder="URL IOS"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Update Required</Form.Label>
                <Form.Control
                  value={data?.appsUpdateRequired}
                  type="text"
                  placeholder="Update Required"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Max Chat CS Agent</Form.Label>
                <Form.Control
                  value={data?.maxCsagentChat}
                  type="number"
                  placeholder="Max Chat CS Agent"
                  onChange = {(e) => setData({...data, maxCsagentChat: Number(e.target.value)}) }
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Info Update</Form.Label>
                <Form.Control
                  value={data?.appsUpdateInfo}
                  type="text"
                  placeholder="Info Update"
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </CardBody>
      <CardFooter>
        <Button
          className="bg-primary"
          onClick={handleUpdate}
        //   disabled={updateUserResponse.code === 200 ? true : false}
          >
          Simpan
        </Button>
      </CardFooter>
    </Card>
  );
}
const mapStateToProps = (state) => ({
  getDetailLiveChatSettingResponse: state.livechat.getDetailLiveChatSettingResponse,
  putDetailLiveChatSettingResponse: state.livechat.putDetailLiveChatSettingResponse
});
const mapDispatchToProps = {
  getDetailLiveChatSetting: (payload) => getDetailLiveChatSettingProps(payload),
  putDetailLiveChatSetting: (payload) => putDetailLiveChatSettingProps(payload)
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateLivechatSetting);
