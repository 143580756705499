import * as CONST from '@myrepublic-awards/awardConstant';
import * as STATE from '@myrepublic-awards/awardInitialState';

const awardsInitialState = {
  ...STATE.awardInitialState,
  action: '',
};

const awardsReducer = (state = awardsInitialState, action) => {
  const { payload, type } = action;
  const actions = {
    [CONST.GET_AWARDS]: () => ({
      ...state,
      getAwardFetch: true,
      getAwardsParams: payload,
      action: type,
    }),
    [CONST.GET_AWARDS_SUCCESS]: () => ({
      ...state,
      getAwardFetch: false,
      getAwardsResponse: payload,
      action: type,
    }),
    [CONST.GET_AWARDS_FAILED]: () => ({
      ...state,
      getAwardFetch: false,
      postAwardError: payload,
      action: type,
    }),
    [CONST.POST_AWARDS]: () => ({
      ...state,
      postAwardFetch: true,
      postAwardParams: payload,
      action: type,
    }),
    [CONST.POST_AWARDS_SUCCESS]: () => ({
      ...state,
      postAwardFetch: false,
      postAwardResponse: payload,
      action: type,
    }),
    [CONST.POST_AWARDS_FAILED]: () => ({
      ...state,
      postAwardFetch: false,
      postAwardError: payload,
      action: type,
    }),
    [CONST.UPDATE_AWARDS]: () => ({
      ...state,
      putAwardFetch: false,
      putAwardParams: payload,
      action: type,
    }),
    [CONST.UPDATE_AWARDS_SUCCESS]: () => ({
      ...state,
      putAwardFetch: false,
      putAwardResponse: payload,
      action: type,
    }),
    [CONST.UPDATE_AWARDS_FAILED]: () => ({
      ...state,
      putAwardFetch: false,
      putAwardError: payload,
      action: type,
    }),
    [CONST.DELETE_AWARDS]: () => ({
      ...state,
      deleteAwardFetch: false,
      deleteAwardParams: payload,
      action: type,
    }),
    [CONST.DELETE_AWARD_SUCCESS]: () => ({
      ...state,
      deleteAwardFetch: false,
      deleteAwardResponse: payload,
      action: type,
    }),
    [CONST.DELETE_AWARD_FAILED]: () => ({
      ...state,
      deleteAwardFetch: false,
      deleteAwardError: payload,
      action: type,
    }),
    [CONST.UPLOAD_IMAGE]: () => ({
      ...state,
      uploadImageFetch: false,
      uploadImageParams: payload,
      action: type,
    }),
    [CONST.UPLOAD_IMAGE_SUCCESS]: () => ({
      ...state,
      uploadImageFetch: false,
      uploadImageResponse: payload,
      action: type,
    }),
    [CONST.UPLOAD_IMAGE_FAILED]: () => ({
      ...state,
      uploadImageFetch: false,
      uploadImageError: payload,
      action: type,
    }),
    [CONST.SET_IMAGE_URL]: () => ({
      ...state,
      uploadImageFetch: false,
      url: payload,
    }),

    DEFAULT: () => state,
  };
  return (actions[type] || actions.DEFAULT)();
};

export default awardsReducer;
