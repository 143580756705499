import { call, put, takeLatest, select } from 'redux-saga/effects';
import { RESPONSE_STATUS } from '@myrepublic-config/env';
import { forgotPasswordApi } from '@myrepublic-forgotPassword/forgotPasswordApi';
import {
  forgotPasswordSuccess,
  forgotPasswordFailed,
} from '@myrepublic-forgotPassword/forgotPasswordAction';
import * as CONST from '@myrepublic-forgotPassword/forgotPasswordConstant';

function* forgotPasswordSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(forgotPasswordApi, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(forgotPasswordSuccess(response.data));
        break;
      default:
        yield put(forgotPasswordFailed(response.data));
        break;
    }
  } catch (error) {
    if (error.response) {
      switch (error.response.status) {
        case RESPONSE_STATUS.UNAUTHORIZED:
          break;
        default:
          yield put(forgotPasswordFailed(error.response));
          break;
      }
    } else {
      yield put(forgotPasswordFailed(error.response));
    }
  }
}

export default [takeLatest(CONST.FORGOT_PASSWORD, forgotPasswordSaga)];
