import React, { useEffect, useState, createRef } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Styles from './styles';
import {
  resetPassword as resetPasswordProps,
  formResetPassword as formResetPasswordProps,
} from '@myrepublic-resetPassword/resetPasswordAction';

function ResetPassword(props) {
  const {
    resetPassword,
    resetPasswordResponse,
    resetPasswordError,
    resetPasswordFetch,
    formResetPassword,
    formResetPasswordResponse,
    formResetPasswordError,
    formResetPasswordFetch,
    formResetPasswordHasRequested,
  } = props;
  const ref = createRef();
  const params = useParams();
  const { search } = useLocation();
  const history = useHistory();
  const resetPasswordToken = new URLSearchParams(search).get(
    'resetPasswordToken'
  );
  const styles = Styles();
  const [inputs, setInputs] = useState({
    newPassword: '',
    newPasswordConfirm: '',
  });
  const [submitted, setSubmitted] = useState(false);
  const [open, setOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  let closeToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  let handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  let validationForm = () => {
    if (
      inputs?.newPassword !== '' &&
      inputs?.newPasswordConfirm !== '' &&
      resetPasswordToken !== ''
    ) {
      return true;
    }

    return false;
  };

  let handleSubmit = (e) => {
    e.preventDefault();

    setSubmitted(true);
    setOpen(false);
    setResponseMessage('');
    if (validationForm()) {
      const req = {
        resetPasswordToken: resetPasswordToken,
        newPassword: inputs?.newPassword,
        newPasswordConfirm: inputs?.newPasswordConfirm,
      };
      resetPassword(req);
    }
  };

  useEffect(() => {
    if (submitted && resetPasswordError.status !== undefined) {
      setOpen(true);
      setResponseMessage(
        resetPasswordError?.status === 404 ? 'Akun tidak ditemukan' : ''
      );
    }
    if (submitted && resetPasswordResponse.status !== undefined) {
      setOpen(true);
      setResponseMessage(
        resetPasswordResponse?.code === 200
          ? resetPasswordResponse?.message
          : ''
      );
    }
  }, [submitted, resetPasswordError, resetPasswordResponse]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(true);
      }, 3000);
    }
    if (responseMessage) {
      setTimeout(() => {
        setResponseMessage('');
      }, 3000);
    }
  }, [open, responseMessage]);

  useEffect(() => {
    if (resetPasswordToken) {
      formResetPassword({
        resetPasswordToken: resetPasswordToken,
      });
    }
  }, []);

  useEffect(() => {
    if (formResetPasswordResponse?.code === 200) {
      setOpen(true);
      setResponseMessage(formResetPasswordResponse?.message);
    }
  }, [formResetPasswordResponse]);

  return (
    <Container
      component="main"
      maxWidth="xs"
      className={styles.container}
      ref={ref}>
      <Snackbar
        open={open && responseMessage !== ''}
        autoHideDuration={4000}
        onClose={closeToast}>
        <Alert
          onClose={closeToast}
          severity={
            resetPasswordResponse?.code === 200 ||
            formResetPasswordResponse?.code === 200
              ? 'success'
              : 'error'
          }>
          {responseMessage !== '' ? responseMessage : ''}
        </Alert>
      </Snackbar>
      <CssBaseline />
      <div className={styles.paper}>
        <img src="logo.png" className={styles.logo} />
        <form className={styles.form} onSubmit={handleSubmit}>
          <TextField
            type="password"
            variant="outlined"
            margin="normal"
            fullWidth
            id="newPassword"
            label="Password Baru"
            name="newPassword"
            autoComplete="Password Baru"
            size="small"
            onChange={handleChange}
            autoFocus
            error={submitted && !inputs?.newPassword ? true : false}
            helperText={submitted && !inputs?.newPassword ? 'Harus diisi' : ''}
          />
          <TextField
            type="password"
            variant="outlined"
            margin="normal"
            fullWidth
            id="newPasswordConfirm"
            label="Konfirmasi Password Baru"
            name="newPasswordConfirm"
            autoComplete="Konfirmasi Password Baru"
            size="small"
            onChange={handleChange}
            autoFocus
            error={submitted && !inputs?.newPasswordConfirm ? true : false}
            helperText={
              submitted && !inputs?.newPasswordConfirm ? 'Harus diisi' : ''
            }
          />
          <Button
            disabled={resetPasswordFetch}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={styles.submit}>
            Submit
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/login" variant="body2">
                Login Kembali?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="#">
            My Republic
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Box>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  resetPasswordResponse: state.resetPassword.resetPasswordResponse,
  resetPasswordError: state.resetPassword.resetPasswordError,
  resetPasswordFetch: state.resetPassword.resetPasswordFetch,
  formResetPasswordResponse: state.resetPassword.formResetPasswordResponse,
  formResetPasswordError: state.resetPassword.formResetPasswordError,
  formResetPasswordFetch: state.resetPassword.formResetPasswordFetch,
  formResetPasswordHasRequested:
    state.resetPassword.formResetPasswordHasRequested,
});

const mapDispatchToProps = {
  resetPassword: (payload) => resetPasswordProps(payload),
  formResetPassword: (payload) => formResetPasswordProps(payload),
};
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
