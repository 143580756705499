export const GET_AWARDS_SUCCESS = 'GET_AWARDS_SUCCESS';
export const GET_AWARDS = 'GET_AWARDS';
export const GET_AWARDS_FAILED = 'GET_AWARDS_FAILED';

export const POST_AWARDS = 'POST_AWARDS';
export const POST_AWARDS_SUCCESS = 'POST_AWARDS_SUCCESS';
export const POST_AWARDS_FAILED = 'POST_AWARDS_FAILED';

export const UPDATE_AWARDS = 'UPDATE_AWARDS';
export const UPDATE_AWARDS_SUCCESS = 'UPDATE_AWARDS_SUCCESS';
export const UPDATE_AWARDS_FAILED = 'UPDATE_AWARDS_FAILED';

export const DELETE_AWARDS = 'DELETE_AWARD';
export const DELETE_AWARD_SUCCESS = 'DELETE_AWARD_SUCCESS';
export const DELETE_AWARD_FAILED = 'DELETE_AWARD_FAILED';

export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILED = 'UPLOAD_IMAGE_FAILED';

export const SET_IMAGE_URL = 'SET_IMAGE_URL';
