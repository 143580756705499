export const awardInitialState = {
  getAwardFetch: false,
  getAwardsParams: {},
  getAwardsResponse: [],
  getAwardError: {
    message: '',
  },

  postAwardFetch: false,
  postAwardParams: {},
  postAwardResponse: [],
  postAwardError: {
    message: '',
  },

  putAwardFetch: false,
  putAwardParams: {},
  putAwardResponse: [],
  putAwardError: {
    message: '',
  },

  deleteAwardFetch: false,
  deleteAwardParams: {},
  deleteAwardResponse: [],
  deleteAwardError: {
    message: '',
  },

  uploadImageFetch: false,
  uploadImageParams: {},
  uploadImageResponse: [],
  uploadImageError: {
    message: '',
  },
};
