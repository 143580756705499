import * as CONST from '@myrepublic-finishPassword/finishPasswordConstant';

export const finishPassword = (payload) => ({
  type: CONST.FINISH_PASSWORD,
  payload,
});

export const finishPasswordSuccess = (payload) => ({
  type: CONST.FINISH_PASSWORD_SUCCESS,
  payload,
});

export const finishPasswordFailed = (payload) => ({
  type: CONST.FINISH_PASSWORD_FAILED,
  payload,
});
