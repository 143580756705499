import * as CONST from '@myrepublic-forgotPassword/forgotPasswordConstant';

export const forgotPassword = (payload) => ({
  type: CONST.FORGOT_PASSWORD,
  payload,
});

export const forgotPasswordSuccess = (payload) => ({
  type: CONST.FORGOT_PASSWORD_SUCCESS,
  payload,
});

export const forgotPasswordFailed = (payload) => ({
  type: CONST.FORGOT_PASSWORD_FAILED,
  payload,
});
