import * as CONST from '@myrepublic-resetPassword/resetPasswordConstant';

export const resetPassword = (payload) => ({
  type: CONST.RESET_PASSWORD,
  payload,
});

export const resetPasswordSuccess = (payload) => ({
  type: CONST.RESET_PASSWORD_SUCCESS,
  payload,
});

export const resetPasswordFailed = (payload) => ({
  type: CONST.RESET_PASSWORD_FAILED,
  payload,
});

// form
export const formResetPassword = (payload) => ({
  type: CONST.FORM_RESET_PASSWORD,
  payload,
});

export const formResetPasswordSuccess = (payload) => ({
  type: CONST.FORM_RESET_PASSWORD_SUCCESS,
  payload,
});

export const formResetPasswordFailed = (payload) => ({
  type: CONST.FORM_RESET_PASSWORD_FAILED,
  payload,
});
