import { call, put, takeLatest, select } from 'redux-saga/effects';
import { RESPONSE_STATUS } from '@myrepublic-config/env';
import {
  deleteDataProduct,
  deleteDataProductAddOn,
  detailDataProduct,
  getDataProduct,
  getDataProductTv,
  postDataProduct,
  putDataProduct,
  uploadDataImage,
} from '@myrepublic-products/productApi';
import {
  deleteProductFailed,
  deleteProductSuccess,
  deleteProductAddOnFailed,
  deleteProductAddOnSuccess,
  detailProductFailed,
  detailProductSuccess,
  getProductFailed,
  getProductSuccess,
  getProductTvFailed,
  getProductTvSuccess,
  postProductFailed,
  postProductSuccess,
  putProductFailed,
  putProductSuccess,
  uploadImageFailed,
  uploadImageSuccess,
  setImageUrl,
} from '@myrepublic-products/productAction';
import * as CONST from '@myrepublic-products/productConstant';
import { toast } from 'react-toastify';

function* getProductSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(getDataProduct, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(getProductSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(getProductFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(getProductFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(getProductFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(getProductFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(getProductFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(getProductFailed(error.response));
        break;
      default:
    }
  }
}

function* getProductTvSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(getDataProductTv, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(getProductTvSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(getProductTvFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(getProductTvFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(getProductTvFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(getProductTvFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(getProductTvFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(getProductTvFailed(error.response));
        break;
      default:
    }
  }
}

function* postProductSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(postDataProduct, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(postProductSuccess(response.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(postProductFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(postProductFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(postProductFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(postProductFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(postProductFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(postProductFailed(error.response));
        break;
      default:
    }
  }
}

function* putProductSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(putDataProduct, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(putProductSuccess(response.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(putProductFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(putProductFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(putProductFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(putProductFailed(error.response));
        toast.success(error.response);
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(putProductFailed(error.response));
        toast.success(error.response);
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(putProductFailed(error.response));
        toast.success(error.response);
        break;
      default:
    }
  }
}

function* deleteProductSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(deleteDataProduct, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(deleteProductSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(deleteProductFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(deleteProductFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(deleteProductFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(deleteProductFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(deleteProductFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(deleteProductFailed(error.response));
        break;
      default:
    }
  }
}

function* deleteProductAddOnSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(deleteDataProductAddOn, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(deleteProductAddOnSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(deleteProductAddOnFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(deleteProductAddOnFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(deleteProductAddOnFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(deleteProductAddOnFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(deleteProductAddOnFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(deleteProductAddOnFailed(error.response));
        break;
      default:
    }
  }
}

function* detailProductSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(detailDataProduct, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(detailProductSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(detailProductFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(detailProductFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(detailProductFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(detailProductFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(detailProductFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(detailProductFailed(error.response));
        break;
      default:
    }
  }
}

function* uploadImageSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(uploadDataImage, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(uploadImageSuccess(response.data.data));
        yield put(setImageUrl(response.data.data.url));
        break;
      default:
        yield put(uploadImageFailed(response.data));
        break;
    }
  } catch (error) {
    if (error.response) {
      switch (error.response.status) {
        case RESPONSE_STATUS.UNAUTHORIZED:
          break;
        default:
          yield put(uploadImageFailed(error.response.data));
          break;
      }
    } else {
      yield put(uploadImageFailed(error.response.data));
    }
  }
}

export default [
  takeLatest(CONST.GET_PRODUCTS, getProductSaga),
  takeLatest(CONST.GET_PRODUCTS_TV, getProductTvSaga),
  takeLatest(CONST.POST_PRODUCTS, postProductSaga),
  takeLatest(CONST.UPDATE_PRODUCTS, putProductSaga),
  takeLatest(CONST.DELETE_PRODUCTS, deleteProductSaga),
  takeLatest(CONST.DELETE_PRODUCTS_ADD_ON, deleteProductAddOnSaga),
  takeLatest(CONST.DETAIL_PRODUCTS, detailProductSaga),
  takeLatest(CONST.UPLOAD_IMAGE, uploadImageSaga),
];
