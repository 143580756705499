import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isAllowed } from '@myrepublic-utils';

function PrivateRoute({ component: Component, access, ...rest }) {
  const session = useSelector((state) => state.session);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!session.token && session.token === '') {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          );
        }

        // logged in so return component
        if (isAllowed(session, access) || access === '') {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{ pathname: '/unauthorized', state: { from: props.location } }}
            />
          );
        }
      }}
    />
  );
}

export default PrivateRoute;
