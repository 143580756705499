import {
  all,
  put,
  call,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { RESPONSE_STATUS } from '@myrepublic-config/env';
import auth from '@myrepublic-auth/authSaga';
import awardSaga from '@myrepublic-awards/awardSaga';
import troubleSaga from '@myrepublic-troubleshooting/troubleShootingSaga';
import forgotPasswordSaga from '@myrepublic-forgotPassword/forgotPasswordSaga';
import resetPasswordSaga from '@myrepublic-resetPassword/resetPasswordSaga';
import productSaga from '@myrepublic-products/productSaga';
import { getTableApi } from './bootstrapApi';
import { getDataTableFailed, getDataTableSuccess } from './bootstrapAction';
import * as CONST from '@myrepublic-bootstrap/bootstrapContant';
import liveChatSaga from '@myrepublic-livechat/liveChatSaga';
import usersSaga from '@myrepublic-users/usersSaga';
import wokumoSaga from '@myrepublic-wokumo/wokumoSaga';

function* dataTableSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(getTableApi, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(getDataTableSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(getDataTableFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(getDataTableFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(getDataTableFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(getDataTableFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(getDataTableFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(getDataTableFailed(error.response));
        break;
      default:
    }
  }
}

const tableSaga = [takeLatest(CONST.GET_DATA_TABLE, dataTableSaga)];

function* bootstrapSaga() {
  yield all([
    ...auth,
    ...awardSaga,
    ...troubleSaga,
    ...forgotPasswordSaga,
    ...resetPasswordSaga,
    ...tableSaga,
    ...productSaga,
    ...liveChatSaga,
    ...usersSaga,
    ...wokumoSaga
  ]);
}

export default bootstrapSaga;
