import { call, put, takeLatest, select } from 'redux-saga/effects';
import { RESPONSE_STATUS } from '@myrepublic-config/env';
import {
  deleteDataAward,
  getDataAward,
  postDataAward,
  putDataAward,
  uploadDataImage,
} from '@myrepublic-awards/awardApi';
import {
  deleteAwardFailed,
  deleteAwardSuccess,
  getAwardFailed,
  getAwardSuccess,
  postAwardFailed,
  postAwardSuccess,
  putAwardFailed,
  putAwardSuccess,
  uploadImageFailed,
  uploadImageSuccess,
  setImageUrl,
} from '@myrepublic-awards/awardAction';
import * as CONST from '@myrepublic-awards/awardConstant';

function* getAwardSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(getDataAward, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(getAwardSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(getAwardFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(getAwardFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(getAwardFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(getAwardFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(getAwardFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(getAwardFailed(error.response));
        break;
      default:
    }
  }
}

function* postAwardSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(postDataAward, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(postAwardSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(postAwardFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(postAwardFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(postAwardFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(postAwardFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(postAwardFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(postAwardFailed(error.response));
        break;
      default:
    }
  }
}

function* putAwardSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(putDataAward, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(putAwardSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(putAwardFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(putAwardFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(putAwardFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(putAwardFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(putAwardFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(putAwardFailed(error.response));
        break;
      default:
    }
  }
}

function* deleteAwardSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(deleteDataAward, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(deleteAwardSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(deleteAwardFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(deleteAwardFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(deleteAwardFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(deleteAwardFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(deleteAwardFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(deleteAwardFailed(error.response));
        break;
      default:
    }
  }
}

function* uploadImageSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(uploadDataImage, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(uploadImageSuccess(response.data.data));
        yield put(setImageUrl(response.data.data.url));
        break;
      default:
        yield put(uploadImageFailed(response.data));
        break;
    }
  } catch (error) {
    if (error.response) {
      switch (error.response.status) {
        case RESPONSE_STATUS.UNAUTHORIZED:
          break;
        default:
          yield put(uploadImageFailed(error.response.data));
          break;
      }
    } else {
      yield put(uploadImageFailed(error.response.data));
    }
  }
}

export default [
  takeLatest(CONST.GET_AWARDS, getAwardSaga),
  takeLatest(CONST.POST_AWARDS, postAwardSaga),
  takeLatest(CONST.UPDATE_AWARDS, putAwardSaga),
  takeLatest(CONST.DELETE_AWARDS, deleteAwardSaga),
  takeLatest(CONST.UPLOAD_IMAGE, uploadImageSaga),
];
