import { api } from '@myrepublic-bootstrap/bootstrapApi';

export const getDataMessages = (payload) => {
  const { accessToken, ...data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.get('/api/v1/web/chat/grouped', data);
};

export const getDataSelectedMessage = (payload) => {
  const { accessToken, ...data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.get(`/api/v1/web/chat/${data.id}`);
};

export const postDataMessage = (payload) => {
  const { accessToken, ...data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.post('/api/v1/web/chat/reply', data);
};

export const putReadMessage = (payload) => {
  const { accessToken, ...data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.get(`/api/v1/web/chat/read/${data.id}`);
};

export const takeByCsagent = (payload) => {
  const { accessToken, ...data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.get(`/api/v1/web/chat/takeByCsagent/${data.id}`);
};

export const closeChatByCsagent = (payload) => {
  const { accessToken, ...data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.get(`/api/v1/web/chat/closeByCsagent/${data.id}`);
};

export const uploadDataImage = (payload) => {
  const { accessToken, data } = payload;
  let formData = new FormData();
  formData.append('image', data.image);
  formData.append('type', data.type);
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.post(`/api/v1/cdn/upload`, formData);
};

export const postLiveChatSettings = (payload) => {
  const { accessToken, ...data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.post('/api/v1/web/settings', data);
}

export const getDetailLiveChatSetting = (payload) => {
  const { accessToken, ...data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.get(`/api/v1/web/settings/${data.id}`);
}

export const putDetailLiveChatSetting = (payload) => {
  const { accessToken, ...data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.put(`/api/v1/web/settings/${data.id}`, data.payload);
}

export const postCSAgentChatStatistics = (payload) => {
  const { accessToken, ...data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.post('/api/v1/web/csagentChatStatistics', data);
}

export const postCSAgentChatStatisticsById = (payload) => {
  const { accessToken, ...data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.post('/api/v1/web/users/chatStatistics', data);
}

export const getCSAgentChatStatisticsSummary = (payload) => {
  const { accessToken, ...data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.get('/api/v1/web/csagentChatStatistics/summary');
}

export const postCSAgentChatMonitoring = (payload) => {
  const { accessToken, ...data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.post('/api/v1/web/csMonitoring', data);
}

export const postCSAgentForceLogout = (payload) => {
  const { accessToken, ...data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.post('/api/v1/web/csMonitoring/forceLogout', data);
}

export const postCSAgentHistory = (payload) => {
  const { accessToken, ...data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.post('api/v1/web/users/chatHistory', data);
}