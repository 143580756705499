export const wokumoInitialState = {
    getVendorFetch: false,
    getVendorParams: {},
    getVendorResponse: [],
    getVendorError: {
      message: '',
    },

    getWorkOrderFetch: false,
    getWorkOrderParams: {},
    getWorkOrderResponse: [],
    getWorkOrderError: {
      message: '',
    },

    postWorkOrderFetch: false,
    postWorkOrderParams: {},
    postWorkOrderResponse: [],
    postWorkOrderError: {
      message: '',
    },

    putVendorStatusFetch: false,
    putVendorStatusParams: {},
    putVendorStatusResponse: [],
    putVendorStatusError: {
      message: '',
    },

    postVendorFetch: false,
    postVendorParams: {},
    postVendorResponse: [],
    postVendorError: {
      message: '',
    },

    postVendorStatisticsFetch: false,
    postVendorStatisticsParams: {},
    postVendorStatisticsResponse: [],
    postVendorStatisticsError: {
      message: '',
    },

    putVendorFetch: false,
    putVendorParams: {},
    putVendorResponse: [],
    putVendorError: {
      message: '',
    },

    getVendorDetailFetch: false,
    getVendorDetailParams: {},
    getVendorDetailResponse: [],
    getVendorDetailError: {
      message: '',
    },
};
