import { api } from '@myrepublic-bootstrap/bootstrapApi';

export const resetPasswordApi = (payload) => {
  const { accessToken, ...data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.post(`/api/v1/auth/resetPassword`, data);
};

export const formResetPasswordApi = (payload) => {
  const { accessToken, resetPasswordToken } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.get(
    `/api/v1/auth/formResetPassword?resetPasswordToken=` + resetPasswordToken
  );
};
