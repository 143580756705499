export const messageInitialState = {
    getMessagesFetch: false,
    getMessagesParams: {},
    getMessagesResponse: [],
    getMessagesError: {
      message: '',
    },

    getSelectedMessageFetch: false,
    getSelectedMessageParams: {},
    getSelectedMessageResponse: [],
    getSelectedMessageError: {
      message: '',
    },

    postMessageFetch: false,
    postMessageParams: {},
    postMessageResponse: [],
    postMessageError: {
      message: '',
    },

    putReadMessageFetch: false,
    putReadMessageParams: {},
    putReadMessageResponse: [],
    putReadMessageError: {
      message: '',
    },

    takeByCsagentFetch: false,
    takeByCsagentParams: {},
    takeByCsagentResponse: [],
    takeByCsagentError: {
      message: '',
    },

    closeChatByCsagentFetch: false,
    closeChatByCsagentParams: {},
    closeChatByCsagentResponse: [],
    closeChatByCsagentError: {
      message: '',
    },

    uploadImageFetch: false,
    uploadImageParams: {},
    uploadImageResponse: [],
    uploadImageError: {
      message: '',
    },

    postLiveChatSettingsFetch: false, 
    postLiveChatSettingsParams: {},
    postLiveChatSettingsResponse: [],
    postLiveChatSettingsError: {
      message: '',
    },

    getDetailLiveChatSettingFetch: false, 
    getDetailLiveChatSettingParams: {},
    getDetailLiveChatSettingResponse: [],
    getDetailLiveChatSettingError: {
      message: '',
    },

    putDetailLiveChatSettingFetch: false, 
    putDetailLiveChatSettingParams: {},
    putDetailLiveChatSettingResponse: [],
    putDetailLiveChatSettingError: {
      message: '',
    },

    postCSAgentChatStatisticsFetch: false, 
    postCSAgentChatStatisticsParams: {},
    postCSAgentChatStatisticsResponse: [],
    postCSAgentChatStatisticsError: {
      message: '',
    },

    postCSAgentChatStatisticsByIdFetch: false, 
    postCSAgentChatStatisticsByIdParams: {},
    postCSAgentChatStatisticsByIdResponse: [],
    postCSAgentChatStatisticsByIdError: {
      message: '',
    },

    postCSAgentChatSummaryFetch: false, 
    postCSAgentChatSummaryParams: {},
    postCSAgentChatSummaryResponse: [],
    postCSAgentChatSummaryError: {
      message: '',
    },

    postCSAgentChatMonitoringFetch: false, 
    postCSAgentChatMonitoringParams: {},
    postCSAgentChatMonitoringResponse: [],
    postCSAgentChatMonitoringError: {
      message: '',
    },

    postCSAgentForceLogoutFetch: false, 
    postCSAgentForceLogoutParams: {},
    postCSAgentForceLogoutResponse: [],
    postCSAgentForceLogoutError: {
      message: '',
    },

    postCSAgentHistoryFetch: false, 
    postCSAgentHistoryParams: {},
    postCSAgentHistoryResponse: [],
    postCSAgentHistoryError: {
      message: '',
    }
};
  