import { call, put, takeLatest, select } from 'redux-saga/effects';
import { RESPONSE_STATUS } from '@myrepublic-config/env';
import {
  answerTroubleApi,
  createTroubleApi,
  deleteAnswerTroubleApi,
  deleteTroubleApi,
  getAnswerTroubleApi,
  getTroubleApi,
  putAnswerTroubleApi,
  putTroubleApi,
} from '@myrepublic-troubleshooting/troubleShootingApi';
import {
  getDataTroubleSuccess,
  getDataTroubleFailed,
  createDataTroubleSuccess,
  createDataTroubleFailed,
  deleteDataTroubleSuccess,
  deleteDataTroubleFailed,
  answerDataTroubleSuccess,
  answerDataTroubleFailed,
  putDataTroubleSuccess,
  putDataTroubleFailed,
  deleteAnswerDataTroubleSuccess,
  deleteAnswerDataTroubleFailed,
  getAnswerTroubleSuccess,
  getAnswerTroubleFailed,
  putAnswerTroubleSuccess,
  putAnswerTroubleFailed,
} from '@myrepublic-troubleshooting/troubleShootingAction';
import * as CONST from '@myrepublic-troubleshooting/troubleShootingConstant';

function* getDataTroubleSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(getTroubleApi, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(getDataTroubleSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(getDataTroubleFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(getDataTroubleFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(getDataTroubleFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(getDataTroubleFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(getDataTroubleFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(getDataTroubleFailed(error.response));
        break;
      default:
    }
  }
}

function* postDataTroubleSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(createTroubleApi, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(createDataTroubleSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(createDataTroubleFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(createDataTroubleFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(createDataTroubleFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(createDataTroubleFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(createDataTroubleFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(createDataTroubleFailed(error.response));
        break;
      default:
    }
  }
}

function* deleteDataTroubleSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(deleteTroubleApi, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(deleteDataTroubleSuccess(response.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(deleteDataTroubleFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(deleteDataTroubleFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(deleteDataTroubleFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(deleteDataTroubleFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(deleteDataTroubleFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(deleteDataTroubleFailed(error.response));
        break;
      default:
    }
  }
}

function* answerDataTroubleSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(answerTroubleApi, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(answerDataTroubleSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(answerDataTroubleFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(answerDataTroubleFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(answerDataTroubleFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(answerDataTroubleFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(answerDataTroubleFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(answerDataTroubleFailed(error.response));
        break;
      default:
    }
  }
}

function* putDataTroubleSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(putTroubleApi, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(putDataTroubleSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(putDataTroubleFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(putDataTroubleFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(putDataTroubleFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(putDataTroubleFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(putDataTroubleFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(putDataTroubleFailed(error.response));
        break;
      default:
    }
  }
}

function* deleteAnswerDataTroubleSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(deleteAnswerTroubleApi, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(deleteAnswerDataTroubleSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(deleteAnswerDataTroubleFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(deleteAnswerDataTroubleFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(deleteAnswerDataTroubleFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(deleteAnswerDataTroubleFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(deleteAnswerDataTroubleFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(deleteAnswerDataTroubleFailed(error.response));
        break;
      default:
    }
  }
}

function* getAnswerTroubleSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(getAnswerTroubleApi, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(getAnswerTroubleSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(getAnswerTroubleFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(getAnswerTroubleFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(getAnswerTroubleFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(getAnswerTroubleFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(getAnswerTroubleFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(getAnswerTroubleFailed(error.response));
        break;
      default:
    }
  }
}

function* putAnswerTroubleSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(putAnswerTroubleApi, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(putAnswerTroubleSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(putAnswerTroubleFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(putAnswerTroubleFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(putAnswerTroubleFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(putAnswerTroubleFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(putAnswerTroubleFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(putAnswerTroubleFailed(error.response));
        break;
      default:
    }
  }
}
export default [
  takeLatest(CONST.GET_DATA_TROUBLE, getDataTroubleSaga),
  takeLatest(CONST.CREATE_DATA_TROUBLE, postDataTroubleSaga),
  takeLatest(CONST.DELETE_DATA_TROUBLE, deleteDataTroubleSaga),
  takeLatest(CONST.ANSWER_DATA_TROUBLE, answerDataTroubleSaga),
  takeLatest(CONST.PUT_DATA_TROUBLE, putDataTroubleSaga),
  takeLatest(CONST.DELETE_ANSWER_DATA_TROUBLE, deleteAnswerDataTroubleSaga),
  takeLatest(CONST.GET_ANSWER_TROUBLE, getAnswerTroubleSaga),
  takeLatest(CONST.PUT_ANSWER_TROUBLE, putAnswerTroubleSaga),
];
