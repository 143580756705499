export const resetPasswordInitialState = {
  resetPasswordFetch: false,
  resetPasswordParams: {},
  resetPasswordResponse: [],
  resetPasswordError: {},
};
export const formResetPasswordInitialState = {
  formResetPasswordFetch: false,
  formResetPasswordParams: {},
  formResetPasswordResponse: [],
  formResetPasswordError: {},
};
