import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';
import {
  putProduct as putProductProps,
  detailProduct as detailProductProps,
  uploadImage as uploadImageProps,
  deleteProductAddOn as deleteProductAddOnProps,
} from '@myrepublic-products/productAction';
import { Delete} from '@material-ui/icons';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DropzoneArea } from 'material-ui-dropzone';
import { Snackbar, IconButton, Select, MenuItem, FormControl } from '@material-ui/core'; 
import Alert from '@material-ui/lab/Alert';
import { values } from 'lodash';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

function UpdateProduct(props) {
  const {
    deleteProductAddOnResponse,
    deleteProductAddOnProductError,
    deleteProductAddOn,
    putProduct,
    putProductResponse,
    detailProduct,
    detailProductResponse,
    uploadImage,
    uploadImageResponse,
    uploadImageFetch,
    uploadImageError,
    icon,
    match: { params },
  } = props;
  const [data, setData] = useState({});
  const [description, setDescription] = useState({ value: '' });
  const [openErrorInfo, setOpenErrorInfo] = useState(false);
  const [errorInfo, setErrorInfo] = useState('');
  const [item, setItem] = useState({ data: [] });
  const history = useHistory();
  const [key, setKey] = useState(0);

  const billingCycleOptions = [
    {value: "initial", placeholder:"Select Billing Cycle"},
    {value: "MONTHLY", placeholder:"Bulanan"},
    {value: "WEEKLY", placeholder:"Mingguan"},
    {value: "YEARLY", placeholder:"Tahunan"},
  ]

  let closeToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenErrorInfo(false);
  };


  const dataVal = () => {
    const value = item.data;
    let data = [];
    if (typeof value === 'object') {
      data = value.map((product, index) => {
        if (product.id === undefined) {
          return {
            name: product.name,
            price: String(product.price),
            billingCycle: product.billingCycle
          }
        }
        return {
          id: product.id,
          name: product.name,
          price: String(product.price),
          billingCycle: product.billingCycle
        };
      });
      return values(data);
    }
  };

  const handleOpenErrorInfo = () => {
    setOpenErrorInfo(true);
  };

  const validateCreateForm = () => {
    let addOnItemList = dataVal();
    if (data?.name === '') {
      setErrorInfo('Nama Add On harus diisi');
      handleOpenErrorInfo();
      return false;
    } else if (data?.code === '') {
      setErrorInfo('Kode harus diisi');
      handleOpenErrorInfo();
      return false;
    } else if (data?.icon === '') {
      setErrorInfo('Gambar harus diupload');
      handleOpenErrorInfo();
      return false;
    } else if (description?.value === '') {
      setErrorInfo('Deskripsi harus diisi');
      handleOpenErrorInfo();
      return false;
    } else {
      addOnItemList.map((items) => {
        if(items.name === undefined || items.price === "undefined" || items.billingCycle === undefined){
          setErrorInfo('Lengkapi semua field data pada item list');
          handleOpenErrorInfo();
          return false;
        }
      })
    }
    return true;
  };
  const handleOnSave = () => {
    if (validateCreateForm() === true) {
      const sendData = {
        ...data,
        addOnItemList: dataVal(),
        description: description.value,
      };

      putProduct({
        ...sendData,
      });
    }
  };

  const onUploadImage = (image) => {
    if (image?.length > 0) {
      uploadImage({ data: { image: image[0], type: 'PRODUCT' } });
    }
  };

  useEffect(() => {
    if (putProductResponse?.code === 200) {
      toast.success(putProductResponse?.message);
      setTimeout(() => {
        history.replace('/products');
      }, 3000);
    }
  }, [putProductResponse]);

  useEffect(() => {
    if (detailProductResponse?.id !== undefined) {
      setData({
        id: detailProductResponse?.id,
        icon: detailProductResponse?.icon,
        name: detailProductResponse?.name,
        code: detailProductResponse?.code,
        description: detailProductResponse?.description,
        addOnItemList: detailProductResponse?.addOnItems,
        tv: false,
      });
      detailProductResponse?.addOnItems?.map((i, index) => {
        item?.data.push({ id: i.id, name: i.name, price: i.price, billingCycle: i.billingCycle });
      });
      setDescription({ value: detailProductResponse?.description });
      // setItem({ data: detailProductResponse?.addOnItems });
    }
  }, [detailProductResponse]);


  useEffect(() => {
    setItem({ data: [] });
    detailProduct({ id: params?.id });
  }, []);

  useEffect(() => {
    setItem({ data: [] });
    detailProduct({ id: params?.id });
  }, [deleteProductAddOnResponse]);

  useEffect(() => {
    if (uploadImageResponse?.url !== undefined) {
      setData({ ...data, icon: uploadImageResponse?.url });
    }
  }, [uploadImageResponse]);

  useEffect(() => {
    if (uploadImageError?.error !== undefined && uploadImageError?.message) {
      setErrorInfo(uploadImageError?.message);
      handleOpenErrorInfo();
      setKey();
    }
  }, [uploadImageError]);

  const onChangeDescription = (e) => {
    setDescription({ ...description, value: e });
  };

  const ItemListComponent = () => {
    const addList = () => {
      let list = parseInt(item?.data.length);
      setItem({ data: [...item.data, {name: undefined, price: undefined}] });
    };

    const onChange = (e, index) => {
      const { name, value } = e.target
      const newArray = [...item.data]
      newArray[index][name] = value
      setItem({data: newArray})
    }

    const onChangeBillingCycle = (e, index) => {
      const { value } = e.target
      if(value !== "initial"){
        const newArray = [...item.data]
        newArray[index]["billingCycle"] = value
        setItem({data: newArray})
      }

    }

    const deleteHandler = async (id, index) => {
      if(id !== undefined){ 
        await deleteProductAddOn({ id: id })
      }
      const tempItem = item.data.splice(index, 1);
      setItem({data: item.data});
    }

    return (
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Item List</Form.Label>
        <div className="d-flex justify-content-end">
          <div
            style={{
              width: '130px',
              borderRadius: '5px',
              height: '20px',
              backgroundColor: '#9c27b0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              color: 'white',
              fontWeight: 'bold',
            }}
            onClick={addList}>
            Tambah Item +
          </div>
        </div>
        {item?.data.map((i, index) => (
            <Row key={index}>
              <Col className="mt-2 mx-6 d-flex justify-content-end">
                <Form.Control
                  className={'sm-6'}
                  type="text"
                  placeholder={'Nama item'}
                  value={i.name}
                  name="name"
                  onChange={(e) => onChange(e, index)}
                />
              </Col>
              <Col className="mt-2 mx-6 d-flex justify-content-end">
                <Form.Control
                  type="number"
                  name="price"
                  placeholder={'Harga'}
                  value={i.price}
                  onChange={(e) => onChange(e, index)}
                />
              </Col>
              <Col className="mt-2 mx-6 d-flex justify-content-end">
              <FormControl fullWidth>
                <Select
                  variant='outlined'
                  value={i?.billingCycle ? i.billingCycle : "initial"}
                  label="billingCycle"
                  onChange={(e) => onChangeBillingCycle(e, index)}
                  style={{marginTop: 5}}
                >
                  {
                    billingCycleOptions.map((option) => (
                      <MenuItem value={option.value}>{option.placeholder}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
              </Col>
              <Col className="mt-2 mx-2 d-flex justify-content-center" xs="1">
                <IconButton color="secondary" onClick={() => deleteHandler(i.id, index)}>
                    <Delete />
                </IconButton>
              </Col>
            </Row>
          )
        )}
      </Form.Group>
    );
  };
  return (
    <Card className="mx-4 card-box">
      <CardHeader>
        <CardTitle>Update Add On</CardTitle>
      </CardHeader>
      <CardBody className="p-3">
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Add On</Form.Label>
            <Row className="mb-3">
              <Col className="mt-2 mx-6 d-flex justify-content-end">
                <Form.Control
                  type="text"
                  placeholder={'Kode'}
                  value={data?.code}
                  onChange={(e) => setData({ ...data, code: e.target.value })}
                />
              </Col>
              <Col className="mt-2 mx-6 d-flex justify-content-end">
                <Form.Control
                  placeholder={'Nama Add On'}
                  value={data?.name}
                  type="text"
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                />
              </Col>
            </Row>

            <Card style={{ textAlign: 'center', alignContent: 'center' }}>
              <img
                src={data.icon}
                style={{
                  height: 80,
                  width: 80,
                  backgroundSize: 'cover',
                  background: 'no-repeat',
                  imageResolution: 'low',
                  alignSelf: 'center',
                }}
                alt="my-rep-img"
              />
            </Card>
            <Form.Label>Update Gambar</Form.Label>
            <Snackbar
              open={openErrorInfo}
              autoHideDuration={4000}
              onClose={closeToast}>
              <Alert
                onClose={closeToast}
                severity="error"
                sx={{ width: '100%' }}>
                {errorInfo}
              </Alert>
            </Snackbar>
            <div className="dropZone">
              <DropzoneArea
                filesLimit={1}
                maxFileSize={3000000}
                onChange={onUploadImage}
                acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                dropzoneText={
                  'Drag and drop gambar atau klik disini untuk mengupload'
                }
                showAlerts={false}
                showFileNames={true}
                // key={key}
              />
            </div>
          </Form.Group>

          {ItemListComponent()}
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Deskripsi</Form.Label>
            <CKEditor
              editor={ClassicEditor}
              data={description?.value}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                // console.log('Editor is ready to use!', editor);
              }}
              onChange={(event, editor) => {
                const value = editor.getData();
                onChangeDescription(value);
              }}
              onBlur={(event, editor) => {
                // console.log('Blur.', editor);
              }}
              onFocus={(event, editor) => {
                // console.log('Focus.', editor);
              }}
            />
          </Form.Group>
        </Form>
      </CardBody>
      <CardFooter>
        <Button
          className="bg-primary"
          onClick={handleOnSave}
          disabled={putProductResponse.code === 200 ? true : false}>
          Simpan
        </Button>
      </CardFooter>
    </Card>
  );
}
const mapStateToProps = (state) => ({
  putProductResponse: state.product.putProductResponse,
  detailProductResponse: state.product.detailProductResponse,
  deleteProductAddOnResponse: state.product.deleteProductAddOnResponse,
  uploadImageResponse: state.product.uploadImageResponse,
  uploadImageError: state.product.uploadImageError,
  icon: state.product.icon,
});
const mapDispatchToProps = {
  putProduct: (payload) => putProductProps(payload),
  detailProduct: (payload) => detailProductProps(payload),
  uploadImage: (payload) => uploadImageProps(payload),
  deleteProductAddOn: (payload) => deleteProductAddOnProps(payload)
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProduct);
