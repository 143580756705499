export const GET_DATA_TROUBLE = 'GET_DATA_TROUBLE';
export const GET_DATA_TROUBLE_SUCCESS = 'GET_DATA_TROUBLE_SUCCESS';
export const GET_DATA_TROUBLE_FAILED = 'GET_DATA_TROUBLE_FAILED';

export const CREATE_DATA_TROUBLE = 'CREATE_DATA_TROUBLE';
export const CREATE_DATA_TROUBLE_SUCCESS = 'CREATE_DATA_TROUBLE_SUCCESS';
export const CREATE_DATA_TROUBLE_FAILED = 'CREATE_DATA_TROUBLE_FAILED';

export const DELETE_DATA_TROUBLE = 'DELETE_DATA_TROUBLE';
export const DELETE_DATA_TROUBLE_SUCCESS = 'DELETE_DATA_TROUBLE_SUCCESS';
export const DELETE_DATA_TROUBLE_FAILED = 'DELETE_DATA_TROUBLE_FAILED';

export const ANSWER_DATA_TROUBLE = 'ANSWER_DATA_TROUBLE';
export const ANSWER_DATA_TROUBLE_SUCCESS = 'ANSWER_DATA_TROUBLE_SUCCESS';
export const ANSWER_DATA_TROUBLE_FAILED = 'ANSWER_DATA_TROUBLE_FAILED';

export const PUT_DATA_TROUBLE = 'PUT_DATA_TROUBLE';
export const PUT_DATA_TROUBLE_SUCCESS = 'PUT_DATA_TROUBLE_SUCCESS';
export const PUT_DATA_TROUBLE_FAILED = 'PUT_DATA_TROUBLE_FAILED';

export const DELETE_ANSWER_DATA_TROUBLE = 'DELETE_ANSWER_DATA_TROUBLE';
export const DELETE_ANSWER_DATA_TROUBLE_SUCCESS =
  'DELETE_ANSWER_DATA_TROUBLE_SUCCESS';
export const DELETE_ANSWER_DATA_TROUBLE_FAILED =
  'DELETE_ANSWER_DATA_TROUBLE_FAILED';

export const GET_ANSWER_TROUBLE = 'GET_ANSWER_TROUBLE';
export const GET_ANSWER_TROUBLE_SUCCESS = 'GET_ANSWER_TROUBLE_SUCCESS';
export const GET_ANSWER_TROUBLE_FAILED = 'GET_ANSWER_TROUBLE_FAILED';

export const PUT_ANSWER_TROUBLE = 'PUT_ANSWER_TROUBLE';
export const PUT_ANSWER_TROUBLE_SUCCESS = 'PUT_ANSWER_TROUBLE_SUCCESS';
export const PUT_ANSWER_TROUBLE_FAILED = 'PUT_ANSWER_TROUBLE_FAILED';

export const RESET_ANSWER = 'RESET_ANSWER';
