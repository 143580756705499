import { call, put, takeLatest, select } from 'redux-saga/effects';
import { RESPONSE_STATUS } from '@myrepublic-config/env';
import { postLoginApi, doLogoutApi } from '@myrepublic-auth/authApis';
import * as CONST from '@myrepublic-auth/authConstant';
import {
  getLoginSuccess,
  getLoginFailed,
  setAuth,
  doLogoutSuccess,
  doLogoutFailed,
} from '@myrepublic-auth/authAction';
import { toast } from 'react-toastify';

function* fetchUser(params) {
  let message = 'Unknown error';
  try {
    const response = yield call(postLoginApi, { ...params.payload });
    let apiResponse = response?.data;
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(getLoginSuccess(response.data.data));
        yield put(setAuth(response.data.data));
        toast.success('Login Berhasil');
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        message =
          apiResponse?.errors?.length > 0 &&
          apiResponse?.errors[0] !== undefined
            ? apiResponse?.errors[0]
            : apiResponse?.message !== undefined
            ? apiResponse?.message
            : 'Unknown Error';
        toast.success(message);
        yield put(getLoginFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(getLoginFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(getLoginFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    let apiResponse = error?.response?.data;
    message =
      apiResponse?.errors?.length > 0 && apiResponse?.errors[0] !== undefined
        ? apiResponse?.errors[0]
        : apiResponse?.message !== undefined
        ? apiResponse?.message
        : 'Unknown Error';
    toast.error(message);
    switch (apiResponse?.code) {
      case RESPONSE_STATUS.ERROR:
        yield put(getLoginFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(getLoginFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(getLoginFailed(error.response));
        break;
        case RESPONSE_STATUS.NOT_FOUND:
          yield put(getLoginFailed(error.response));
          break;
      default:
    }
  }
}

function* doLogout(params) {
  let message = 'Unknown error';
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(doLogoutApi, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(doLogoutSuccess(response.data.data));
        toast.success('Logout Berhasil');
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(doLogoutFailed(response.status));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(doLogoutFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    let apiResponse = error?.response?.data;
    console.log('apiResponse', apiResponse);
    message =
      apiResponse?.errors?.length > 0 && apiResponse?.errors[0] !== undefined
        ? apiResponse?.errors[0]
        : apiResponse?.message !== undefined
        ? apiResponse?.message
        : 'Unknown Error';
    toast.error(message);
    switch (error) {
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(doLogoutFailed(error.response));
        break;
      case RESPONSE_STATUS.ERROR:
        yield put(doLogoutFailed(error.response));
        break;
      default:
    }
  }
}

export default [
  takeLatest(CONST.REQUEST_AUTHENTICATION, fetchUser),
  takeLatest(CONST.DO_LOGOUT, doLogout),
];
