import { api } from '@myrepublic-bootstrap/bootstrapApi';

export const getTroubleApi = (payload) => {
  const { accessToken } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.get(
    `/api/v1/web/troubleshootings?offset=${payload.offset}&limit=${payload.limit}`
  );
};

export const createTroubleApi = (payload) => {
  const { accessToken, ...data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.post(`/api/v1/web/troubleshootings`, data);
};

export const deleteTroubleApi = (payload) => {
  const { accessToken } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.delete(`/api/v1/web/troubleshootings/${payload.id}`);
};

export const answerTroubleApi = (payload) => {
  const { accessToken, ...data } = payload;
  const sendData = {
    title: data?.title,
    description: data?.description,
    troubleshootingId: data?.troubleshootingId,
  };
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.post(`/api/v1/web/troubleshootingAnswers`, sendData);
};

export const putTroubleApi = (payload) => {
  const { accessToken, ...data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.put(`/api/v1/web/troubleshootings/${payload.id}`, data);
};

export const deleteAnswerTroubleApi = (payload) => {
  const { accessToken, ...data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.delete(`/api/v1/web/troubleshootingAnswers/${payload.id}`, data);
};

export const getAnswerTroubleApi = (payload) => {
  const { accessToken } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.get(
    `/api/v1/web/troubleshootingAnswers?troubleshootingId=${payload.id}&offset=${payload.offset}&limit=${payload.limit}`
  );
};

export const putAnswerTroubleApi = (payload) => {
  const { accessToken, ...data } = payload;
  const sendData = {
    title: data?.title,
    description: data?.description,
    troubleshootingId: data?.troubleshootingId,
  };
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.put(`/api/v1/web/troubleshootingAnswers/${payload.id}`, sendData);
};
