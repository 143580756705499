import storage from 'redux-persist/lib/storage';
const PERSIST = {
  active: true,
  reducerVersion: '1.0',
  storeConfig: {
    key: 'root',
    storage,
    whitelist: ['session', 'theme'],
    // transforms: [saveAuthSubsetBlacklistFilter, encryptor],
  },
};

export default PERSIST;
