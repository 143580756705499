import { api } from '@myrepublic-bootstrap/bootstrapApi';

export const getDataAward = (payload) => {
  const { accessToken, ...data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.post(`/api/v1/web/awards`, data);
};

export const postDataAward = (payload) => {
  const { accessToken, ...data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.post(`/api/v1/web/awards/create`, data);
};

export const putDataAward = (payload) => {
  const { accessToken, ...data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.put(`/api/v1/web/awards/update/${payload.id}`, data);
};

export const deleteDataAward = (payload) => {
  const { accessToken } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  console.log("handle delete", payload.id)
  return api.delete(`/api/v1/web/awards/delete/${payload.id}`);
};

export const uploadDataImage = (payload) => {
  const { accessToken, data } = payload;
  let formData = new FormData();
  formData.append('image', data.image);
  formData.append('type', data.type);
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.post(`/api/v1/cdn/upload`, formData);
};
