import * as CONST from '@myrepublic-awards/awardConstant';

export const getAward = (payload) => ({
  type: CONST.GET_AWARDS,
  payload,
});

export const getAwardSuccess = (payload) => ({
  type: CONST.GET_AWARDS_SUCCESS,
  payload,
});

export const getAwardFailed = (payload) => ({
  type: CONST.POST_AWARDS_FAILED,
  payload,
});

export const postAward = (payload) => ({
  type: CONST.POST_AWARDS,
  payload,
});

export const postAwardSuccess = (payload) => ({
  type: CONST.POST_AWARDS_SUCCESS,
  payload,
});

export const postAwardFailed = (payload) => ({
  type: CONST.UPDATE_AWARDS_FAILED,
  payload,
});

export const putAward = (payload) => ({
  type: CONST.UPDATE_AWARDS,
  payload,
});

export const putAwardSuccess = (payload) => ({
  type: CONST.UPDATE_AWARDS_SUCCESS,
  payload,
});

export const putAwardFailed = (payload) => ({
  type: CONST.UPDATE_AWARDS_FAILED,
  payload,
});

export const deleteAward = (payload) => ({
  type: CONST.DELETE_AWARDS,
  payload,
});

export const deleteAwardSuccess = (payload) => ({
  type: CONST.DELETE_AWARD_SUCCESS,
  payload,
});

export const deleteAwardFailed = (payload) => ({
  type: CONST.DELETE_AWARD_FAILED,
  payload,
});

export const uploadImage = (payload) => ({
  type: CONST.UPLOAD_IMAGE,
  payload,
});

export const uploadImageSuccess = (payload) => ({
  type: CONST.UPLOAD_IMAGE_SUCCESS,
  payload,
});

export const uploadImageFailed = (payload) => ({
  type: CONST.UPLOAD_IMAGE_FAILED,
  payload,
});

export const setImageUrl = (payload) => ({
  type: CONST.SET_IMAGE_URL,
  payload,
});
