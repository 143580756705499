import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { Button, IconButton } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import memoize from 'memoize-one';
import ScrollDialog from '../atoms/dialog';
import ProgressLoading from '../atoms/progress';

const selectProps = { indeterminate: (isIndeterminate) => isIndeterminate };
const deleteActions = memoize((deleteHandler) => (
  <IconButton color="secondary" onClick={deleteHandler}>
    <Delete />
  </IconButton>
));

const addActions = memoize((addHandler) => (
  <Button variant="contained" color="primary" onClick={addHandler}>
    <Add /> Add
  </Button>
));

const DatatableServer = ({
  title,
  columns,
  datas,
  total,
  loading,
  deleted,
  edited,
  additem,
  edititem,
  component,
  componentedit,
  pagechange,
  limitchange,
}) => {
  const [row, selectRow] = useState([]);
  const [open, setOpen] = useState(false);

  const handlePageChange = (page) => {
    pagechange(page - 1);
  };

  const handleRowClicked = (row) => {
    // edited(row);
    setOpen(true);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    limitchange(newPerPage, page - 1);
  };

  let handleChange = (state) => {
    selectRow(state.selectedRows);
  };

  let openDialog = () => {
    setOpen(true);
  };

  let closeDialog = () => {
    setOpen(false);
    // edited({});
  };

  let deleteItem = (e) => {
    const rows = row.map((r) => r.id);
    e.preventDefault();
    deleted(rows);
  };

  return (
    <div>
      <ScrollDialog open={open} closeDialog={closeDialog} additem={additem}>
        {component ? component : null}
      </ScrollDialog>
      {/* <ScrollDialog open={open} closeDialog={closeDialog} edititem={edititem}>
        {componentedit ? componentedit : null}
      </ScrollDialog> */}
      <DataTable
        title={title ? title : ''}
        columns={columns}
        data={datas}
        progressPending={loading}
        progressComponent={<ProgressLoading />}
        // selectableRows
        // selectableRowsHighlight
        // selectableRowsSingle
        // selectableRowsNoSelectAll
        // selectableRowsComponent={Checkbox}
        pagination={true}
        paginationServer
        paginationComponentOptions={{
          noRowsPerPage: false,
        }}
        paginationServerOptions={{
          persistSelectedOnPageChange: true,
          persistSelectedOnSort: true,
        }}
        paginationPerPage={10}
        paginationTotalRows={total}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        persistTableHead
        highlightOnHover
        onSelectedRowsChange={handleChange}
        // onRowClicked={handleRowClicked}
        contextActions={deleteActions(deleteItem)}
        actions={addActions(openDialog)}
      />
    </div>
  );
};

export default DatatableServer;
