export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_FAILED = 'GET_PRODUCTS_FAILED';

export const GET_PRODUCTS_TV_SUCCESS = 'GET_PRODUCTS_TV_SUCCESS';
export const GET_PRODUCTS_TV = 'GET_PRODUCTS_TV';
export const GET_PRODUCTS_TV_FAILED = 'GET_PRODUCTS_TV_FAILED';

export const POST_PRODUCTS = 'POST_PRODUCTS';
export const POST_PRODUCTS_SUCCESS = 'POST_PRODUCTS_SUCCESS';
export const POST_PRODUCTS_FAILED = 'POST_PRODUCTS_FAILED';

export const UPDATE_PRODUCTS = 'UPDATE_PRODUCTS';
export const UPDATE_PRODUCTS_SUCCESS = 'UPDATE_PRODUCTS_SUCCESS';
export const UPDATE_PRODUCTS_FAILED = 'UPDATE_PRODUCTS_FAILED';

export const DELETE_PRODUCTS = 'DELETE_PRODUCT';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILED = 'DELETE_PRODUCT_FAILED';

export const DELETE_PRODUCTS_ADD_ON = 'DELETE_PRODUCT_ADD_ON';
export const DELETE_PRODUCT_ADD_ON_SUCCESS = 'DELETE_PRODUCT_ADD_ON_SUCCESS';
export const DELETE_PRODUCT_ADD_ON_FAILED = 'DELETE_PRODUCT_ADD_ON_FAILED';

export const DETAIL_PRODUCTS = 'DETAIL_PRODUCT';
export const DETAIL_PRODUCT_SUCCESS = 'DETAIL_PRODUCT_SUCCESS';
export const DETAIL_PRODUCT_FAILED = 'DETAIL_PRODUCT_FAILED';

export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILED = 'UPLOAD_IMAGE_FAILED';

export const SET_IMAGE_URL = 'SET_IMAGE_URL';

export const RESET_STATE = 'RESET_STATE';
