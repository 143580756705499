import React, { useState, useRef, useEffect } from 'react';
import {
  Typography,
  Container,
  Button,
  Box,
  FormControl,
  Input,
  InputLabel,
} from '@material-ui/core';
import { Card } from 'react-bootstrap';
import useStyles from './styles';
import parse from "html-react-parser";
import { LocationOn, AttachFile, Close } from '@material-ui/icons';
import SendImagePopUp from './SendImagePopUp';

export default function Chat({
  data,
  setMessage,
  onSend,
  message,
  isTextFieldActive,
  senderId,
  setImagePopUp,
  closeChat
}) {
  const classes = useStyles();
  const [value, setValue] = useState(message);

  const onSendHandler = () => {
    setValue('');
    onSend();
  };

  const onChange = (e) => {
    setMessage(e);
    setValue(e.target.value);
  };

  return (
    <Card className={classes.chatContainer}>
      <ChatBox data={data} senderId={senderId}/>
      <Container className={classes.textFieldContainer}>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ flexGrow: 1 }}>
            <FormControl sx={{ m: 1 }} variant="standard" className={classes.textField} fullWidth>
              <InputLabel htmlFor="standard-adornment-password">Type your message...</InputLabel>
              <Input
                id="standard-adornment-password"
                value={value}
                onChange={(e) => onChange(e)}
                onKeyPress= {(e) => {
                  if (e.key === 'Enter') {
                    onSendHandler()
                  }
                }}
                disabled={isTextFieldActive}
              />
            </FormControl>
          </Box>
          <Box>
            <Button 
              className={classes.iconButton} 
              onClick={() => setImagePopUp()}
              disabled={isTextFieldActive}
            >
              <AttachFile/>
            </Button>
            <Button 
              className={classes.iconButton} 
              onClick={() => closeChat()}
              disabled={isTextFieldActive}
            >
              <Close/>
            </Button>
          </Box>
          <Box>
            <Button
              variant="contained"
              disableElevation
              className={classes.buttonChatBox}
              onClick={onSendHandler}
              disabled={isTextFieldActive}>
              <Typography>
                <b>Send</b>
              </Typography>
            </Button>
          </Box>
        </Box>
      </Container>
    </Card>
  );
}

const ChatBox = ({data, senderId}) => {
  const classes = useStyles();
  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  };

  useEffect(() => {
    scrollToBottom()
  }, [data]);

  const sortByDate = arr => {
    const sorter = (a, b) => {
       return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    }
    return arr.sort(sorter);
 };

 const filterData = () => {
   if(data != undefined ) return sortByDate(data);
 }
    return (
        <div className={classes.chatBoxContainer}>
        {filterData()?.map((chat) => (
            <div key={chat?.id}>
                <ChatCard 
                    name={chat?.sender?.name} 
                    message={chat?.message} 
                    date={chat?.createdAt} 
                    isAdmin={chat?.sender?.id !== chat?.recipient?.id ? true : false}
                    chatType={chat?.chatType}
                    chat={chat}
                />
            </div>
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
};

const ChatCard = ({name, message, date, isAdmin, chatType, chat}) => {
  const classes = useStyles();

  const handleNavigate = (chat) => {
    window.location.href = `https://www.google.com/maps/search/?api=1&query=${chat.latitude},${chat.longitude}`
  }

    return (
        <Container className={isAdmin ? classes.chatCardContainerSender : classes.chatCardContainer}>
            <div className={classes.chatCardSenderWrapper}>
                <Typography><b>{name}</b></Typography>
                <Typography className={classes.chatCardDateText}>{date}</Typography>
            </div>
            <Box className={[classes.chatCardMessageBox, isAdmin ? classes.chatCardGrey : classes.chatCardWhite]}>
              { 
                chatType === "PHOTO" ?
                  <img src={chat?.url} style={{maxWidth: 300, objectFit: 'cover'}}/> :
                chatType === "LOCATION" ?
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}} onClick={() => handleNavigate(chat)}>
                    <LocationOn style={{color: "#9C27B0"}}/>
                    <div style={{color: "#9C27B0", fontWeight: 'bold'}}>Click to view location on google maps</div>
                  </div> :
                <ChatHTML message={message}/> 
              }
            </Box>
        </Container>
    )
}

const ChatHTML = ({message}) => {
    const options = {
        replace: domNode => {
            if (domNode.attribs && domNode.name === 'img') {
              return <img style={{ width: '100%' }} src={domNode.attribs.src}/>
            }
        }
    };

    return parse(message, options);
}
