import { api } from '@myrepublic-bootstrap/bootstrapApi';

export const getDataProduct = (payload) => {
  const { accessToken, ...data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.post(`/api/v1/web/addOnProducts`, data);
};
export const getDataProductTv = (payload) => {
  const { accessToken, ...data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.post(`/api/v1/web/addOnTv`, data);
};

export const postDataProduct = (payload) => {
  const { accessToken, ...data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.post(`/api/v1/web/addOnProducts/create`, data);
};

export const putDataProduct = (payload) => {
  const { accessToken, ...data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.put(`/api/v1/web/addOnProducts/${data.id}`, data);
};

export const deleteDataProduct = (payload) => {
  const { accessToken } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  console.log("payload is", payload.id)
  return api.delete(`/api/v1/web/addOnProducts/${payload.id}`);
};

export const deleteDataProductAddOn = (payload) => {
  const { accessToken } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.delete(`/api/v1/web/addOnProducts/itemList/${payload.id}`);
};

export const detailDataProduct = (payload) => {
  const { accessToken } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.get(`/api/v1/web/addOnProducts/${payload.id}`);
};


export const uploadDataImage = (payload) => {
  const { accessToken, data } = payload;
  let formData = new FormData();
  formData.append('image', data.image);
  formData.append('type', data.type);
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.post(`/api/v1/cdn/upload`, formData);
};
